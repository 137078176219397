import { CREATE_PAGE_FAILURE, CREATE_PAGE_REQUEST, DELETE_PAGE_FAILURE, DELETE_PAGE_REQUEST, FETCH_PAGES_FAILURE, FETCH_PAGES_REQUEST, FETCH_PAGES_SUCCESS, UPDATE_PAGE_FAILURE, UPDATE_PAGE_REQUEST } from "../actions/pagesActions";
import { fetchUsersRequest } from "../actions/usersActions";
const initialState = {
    data: '',
    error: '',
    isLoading: false,
    message: '',
}
const pagesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PAGES_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case FETCH_PAGES_SUCCESS:
            return{
                ...state,
                data: action.payload,
                isLoading: false,
                message: action.message
            }
        case FETCH_PAGES_FAILURE:
            return{
                ...state,
                error: action.payload,
                isLoading: false
            }
        case CREATE_PAGE_REQUEST:
            return{
                ...state,
                isLoading: true
            }
        case CREATE_PAGE_FAILURE:
            return{
                ...state,
                error: action.payload,
                message: action.message
            }
        case DELETE_PAGE_REQUEST:
            return{
                ...state,
                isLoading: true
            }
        case DELETE_PAGE_FAILURE:
            return{
                ...state,
                isLoading: false,
                error: action.payload,
                message: action.message
            }
        case UPDATE_PAGE_REQUEST:
            return{
                ...state,
                isLoading: true
            }
        case UPDATE_PAGE_FAILURE:
            return{
                ...state,
                isLoading: false,
                error: action.payload,
                message: action.message
            }
        default: return state
    }
}

export default pagesReducer;
