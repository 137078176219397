import axios from 'axios';
import { getTokenFromStorage } from './helper';


export const customHeaders = {
    'Content-Type': 'application/json',
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const axiosRequest = axios.create({
    baseURL: BASE_URL,
    headers: customHeaders,
});

axiosRequest.interceptors.request.use(
    async (config) => {
        let token = await getTokenFromStorage();
        console.log('api calling', token)
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);



export default axiosRequest;

