import { CHANGE_USER_STATUS_FAILURE, CHANGE_USER_STATUS_REQUEST, CHANGE_USER_STATUS_SUCCESS, DISABLE_SNACKBAR ,USER_DELETE_REQUEST,USER_DELETE_SUCCESS} from "../actions/changeStatusActions";
import { FETCH_USERS_FAILURE, FETCH_USERS_SUCCESS } from "../actions/usersActions";
const initialState = {
    loading: true,
    status: '',
    error: '',
    message: '',
}
const changeStatusReducer = (state = initialState, action) => {
    switch(action.type){
        case CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CHANGE_USER_STATUS_SUCCESS:
            return{
                loading: false,
                status: action.payload,
                message: action.message,
                error: ''
            }
        case FETCH_USERS_FAILURE:
            return{
                loading: false,
                status: false,
                error: action.payload
            }
            


            case USER_DELETE_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case USER_DELETE_SUCCESS:
                return{
                    loading: false,
                    status: action.payload,
                    message: action.message,
                    error: ''
                }
           
                


            
        case DISABLE_SNACKBAR:
            return{
                ...state,
                status: 0
            }
        default: return state
    }
}

export default changeStatusReducer;
