import React , {useState} from 'react';
import RichTextEditor from 'react-rte';

function BodyTextEditor({ value, setValue }) {

    const [editorValue, setEditorValue] =
      React.useState(RichTextEditor.createValueFromString(value, 'html'));
  
    const handleChange = value => {
      setEditorValue(value);
      setValue(value.toString("html"));
    };
    const toolbarConfig = {
        
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
          {label: 'Italic', style: 'ITALIC'},
          {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Heading Large', style: 'header-one'},
          {label: 'Heading Medium', style: 'header-two'},
          {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };

    return (
      <RichTextEditor
        value={editorValue}
        onChange={handleChange}
        required
        id="body-text"
        name="bodyText"
        type="string"
        multiline
        variant="filled"
        toolbarConfig={toolbarConfig}
        style={{ minHeight: '410px', outerWidth: '300px'}}
      />
    );
  }

export default BodyTextEditor