import {useSelector, useDispatch} from 'react-redux';
import * as React from 'react';
import { useEffect, useState }  from 'react';
import {toast} from 'react-toastify';
import {fetchUsers} from '../store/actions/usersActions';
import {changeUserStatus, disableSnackbar ,deleteUser ,subcribedUser} from '../store/actions/changeStatusActions';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './reusables/Loader';
import DataTable from './reusables/DataTable';
import Switch from '@mui/material/Switch';
import SnackBar from './reusables/Snackbar.js';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import PlanModal from '../components/PlanModal';


import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Badge from '@mui/material/Badge';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';



toast.configure()

// function createPlanObject(selectedPlan) {
//     const plans = ['1 month', '3 months', '6 months', '12 months'];
//     const planId = 'com.' + selectedPlan.toLowerCase().replace(/ /g, '');
//     const planTitle = selectedPlan;
//     const planDescription = 'Applicable for ' + selectedPlan.toLowerCase();
//     const planValidity = selectedPlan.replace(/ /g, '_').toUpperCase();


//     function generatePlanId(plan) {
//         const planName = plan.split(" ");
//         let monthName = planName[0] === '1' ? "one" : planName[0] === '3' ? "three" : planName[0] === '6' ? "six" : "twelve";
//         return 'com.' + monthName + planName[1];
//     }
    
//     const planNewId = generatePlanId(selectedPlan);
  
//     return {
//     //   plan_id: planId,
//      plan_id: planNewId,

//       plan_title: planTitle,
//       plan_description: planDescription,
//       plan_validity: planValidity,
//     };
//   }
function createPlanObject(selectedPlan) {
    const plans = ['1 Week','1 month', '3 months', '6 months', '12 months'];
    const planTitle = selectedPlan;
    const planDescription = 'Applicable for ' + selectedPlan.toLowerCase();
    const planValidity = selectedPlan.replace(/ /g, '_').toUpperCase();

    function generatePlanId(plan) {
        const planName = plan.split(" ");
        if (planName[1].toLowerCase() === 'week') {
            return 'weeklyplan';
        } else if (planName[1].toLowerCase() === 'months') {
            switch(planName[0]) {
                // case '1':
                //     return 'monthlyplan';
                case '3':
                    return 'threemonthplan';
                case '6':
                    return 'sixmonthplan';
                case '12':
                    return 'yearlyplan';
                default:
                    throw new Error('Invalid plan');
            }
        }else if (planName[1].toLowerCase() === 'month') {
            return 'monthlyplan';
        }

        
         else {
            throw new Error('Invalid plan');
        }
    }
    
    const planNewId = generatePlanId(selectedPlan);
  
    return {
      plan_id: planNewId,
      plan_title: planTitle,
      plan_description: planDescription,
      plan_validity: planValidity,
      subscribed_by:"admin"
    };
}

function Users(){
    const usersState = useSelector(state => state.users)
    const changeStatus = useSelector(state => state.changeStatus)
    const dispatchReq = useDispatch()
    const [pageSize, setPageSize] = useState(10)
    const [openSnack, setOpenSnack]= useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [userIdToPlan, setUserIdToPlan] = useState(null);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const change_User_Status = async (data, e)=>{
        dispatchReq(changeUserStatus({user_id: data._id,check: e.target.checked}))
    }

    useEffect(()=>{
        console.log(changeStatus)
        if(changeStatus.status == 200){
            setSeverity("success")
            setMessage(changeStatus.message)
            setOpenSnack(true)
        }else if(changeStatus.status == 400){
            setSeverity("error")
            setMessage(changeStatus.message)
            setOpenSnack(true)
        }else if(changeStatus.status == 206){
            setSeverity("warning")
            setMessage(changeStatus.message)
            setOpenSnack(true)
        }
    },[changeStatus])

    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatchReq(disableSnackbar())
    }

    const [columns] = useState([
        { field: 'sn', headerName: 'S.No', Width: 50, minWidth:50, maxWidth: 50},
        // { field: '_id', headerName: 'ID', Width: 250, minWidth:250, maxWidth: 300},
        // { field: 'image', headerName: "User", headerClass: 'new-class', sortable: false,
        //     renderCell: ()=>(
        //     <div>
        //         <img className="inline object-cover w-8 h-8 rounded-full" src="https://images.pexels.com/photos/2589653/pexels-photo-2589653.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" alt="Profile image"/>
        //     </div>
        //     ) , Width: 80, resizable: false,
        //     renderHeader: () => (
        //         <strong>
        //         <span role="img" aria-label="enjoy">
        //         User 🙍
        //         </span>
        //       </strong>
        //       )
        // },
        { field: 'name', headerName:"Name", Width: 200, minWidth:200, maxWidth: 200},
        { field: 'email', headerName:"Email", Width: 300, minWidth:300, maxWidth: 300},
        { field: 'age', headerName:"Age", Width: 100, minWidth:100, maxWidth: 150},
        { field: 'gender', headerName:"Gender", Width: 100, minWidth:100, maxWidth: 150},
        { field: 'roles', headerName:"Roles", Width: 100, minWidth:100, maxWidth: 100},
        { field: 'isSubscribed', headerName: "Subscribed", headerClass: 'new-class', description: 'User Subscribed',
        renderCell :(params)=> (
            <div>
            <Badge
 color={params?.row?.isSubscribed ? 'primary' : 'error'}
//   badgeContent={params?.row?.isSubscribed ? <CheckCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
>
 {params?.row?.isSubscribed ? 'Active' : 'Inactive'}
</Badge>
         </div>
        )},
        { field: 'userStatus', headerName: "Status", headerClass: 'new-class', description: 'Activate or Deactivate a user',
            renderCell :(params)=> (
            <div>
                <Switch inputProps={{ 'aria-label': 'Switch demo' }} defaultChecked={params?.row?.user_status} onChange={(e)=>change_User_Status(params?.row,e)} />
            </div>
            )},
            {
                field: 'Action', headerName: "Action", headerClass: 'new-class', width: 300, sortable: false,
                renderCell: (params) => (
                   <>
                       <div style={{ display: 'flex', gap: '10px' }}>
          
                        <Button onClick={()=>delete_fieldFunc(params?.row)}  variant="contained" color='error' startIcon={<DeleteOutlinedIcon />}>
                            Delete
                        </Button>


                        {
                            params?.row?.isSubscribed ?
                            <Button  disabled variant="contained" color='primary' >
                            Subscribed
                        </Button>
                        :
                        <Button onClick={()=>{
                            // delete_fieldFunc(params?.row)
                            handleOpen(params?.row._id)
                        }}  variant="contained" color='primary' >
                       Subscription
                    </Button>
            }

                        

                       
                        
                        <div>
                            
    
    
        </div>
                    </div>
                    </>
                    
                )
            }
    ])

    const changePaginationSize = (value) => {
        setPageSize(value);
    }

    useEffect(()=>{
        dispatchReq(fetchUsers())
        // if(!userStatusState.loading){
        //     if(userStatusState.status == 200){
        //         toast.success('successful')
        //     }else if(userStatusState.status == 400){
        //         toast.error('Runtime error')
        //     }
        // }
    },[pageSize])

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
      };
    
      const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setUserIdToDelete(null)
      };
    
      const handleDelete = () => {
        // Implement your delete logic here
        console.log('field deleted');
    //  dispatch(delete_field(userIdToDelete))
    dispatchReq(deleteUser({user_id: userIdToDelete}))

     closeDeleteDialog();
     dispatchReq(fetchUsers())


       
      };


      const delete_fieldFunc = (data) => {
        openDeleteDialog()
        setUserIdToDelete(data._id)
    }
    const handleOpen = (userId) => {
        setUserIdToPlan(userId)
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setUserIdToPlan(null)
      };

      const handlePlan = (plan) => {
        console.log('hello plan',plan)
       
        if(userIdToPlan){
            
            let body = {}

            if(plan){
                 body = createPlanObject(plan)
                body.user_id = userIdToPlan
                dispatchReq(subcribedUser(body))
                dispatchReq(fetchUsers())
            }else{
                alert('please select plan again')
            }
           
           

        }

        setOpen(false);
        setUserIdToPlan(null)
        
      };

    
    const usersss = React.useRef(usersState)

    if(usersState.loading){
        return(
            <Loader />
        )
    }else{
        return(
            <div className="home-content">
                <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
                </SnackBar>
                {/* <div className="sales-boxes"> */}
                    {/* <div className="recent-sales box"> */}
                        <DataTable columns={columns} 
                            height={550}
                            width='100%'
                            rows={usersState?.users} 
                            pageSize={pageSize} 
                            setPageSize={(data)=>changePaginationSize(data)}
                            checkboxSelection={false}
                            pagination={true}
                            disableColumnFilter={true}
                            disableColumnSelector={true}
                            disableDensitySelector={true}
                            showQuickFilter={true}
                            />
                {/* </div>
                </div> */}

<DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        handleClose={closeDeleteDialog}
        handleDelete={handleDelete}
        title={"Are you sure you want to delete this user?"}
      />

<PlanModal open={open} onClose={handleClose} userId={123} handlePlan={handlePlan} />
            </div>
        )
    }
}
export default Users

