import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { userSubscriptions } from '../../store/actions/usersActions';
import Box from '@mui/material/Box';
import Loader from '../../components/reusables/Loader';

const UserSubscriptions = () => {

    const subscriptionsList = useSelector(state => state.users.userSubscriptions)
    const isLoading = useSelector(state => state.users.loading)
    const dispatch = useDispatch()

    useEffect(() => {
        getUserSubscriptionsList();
    }, [])

    const getUserSubscriptionsList = async () => {
        dispatch(userSubscriptions());
    }



    const columns = [
        { field: '_id', headerName: 'ID', width: 240 },
        { field: 'plan_id', headerName: 'Plan ID', width: 200 },
        { field: 'user_id', headerName: 'User ID', width: 240 },
        { field: 'plan_price', headerName: 'Price', width: 80 },
        { field: 'plan_title', headerName: 'Title', width: 140 },
        { field: 'plan_startDate', headerName: 'Date', width: 140 },
        { field: 'plan_description', headerName: 'Plan Description',  width: 300 },

    ];

    if (isLoading) {
        return (<Loader />)
    }

    return (
        <div className='home-content'>


            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={subscriptionsList?.length > 0 ? subscriptionsList : []}
                    columns={columns}
                    pageSize={10}
                    sx={{
                        border: 2,
                        borderColor: 'primary.main',
                        borderRadius: '16px',
                        boxShadow: 4,
                        margin: 1,
                        padding: 1
                    }}
                    rowsPerPageOptions={[5, 10, 20, 40]}
                    getRowId={(rows) => rows._id}
                    components={{ Toolbar: GridToolbarQuickFilter }}
                />
            </Box>

        </div>
    )
}
export default UserSubscriptions


const styles = {
    button1: { width: '140px', marginRight: 5 },
    buttonView: { maxWidth: '40px', paddingRight: '5px', maxHeight: '36px', minWidth: '40px', minHeight: '36px' },
}




// import React from 'react'

// const UserSubscriptions = () => {
//     return (
//         <div>list</div>
//     )
// }

// export default UserSubscriptions