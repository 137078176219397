import React, {useState, useEffect, useDebugValue} from "react";
import Modal from './reusables/Modal';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { updateAdminProfile, disableSnackbar } from '../store/actions/usersActions'
import SnackBar from './reusables/Snackbar.js';

const Profile = ({isProfileOpen, setIsProfileOpen}) => {
    const userData = useSelector(store => store.userlogin)
    const userss = useSelector(store => store.users)
    const dispatch = useDispatch()
    const [openSnack, setOpenSnack]= useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")

    useEffect(()=>{
        
        if(userss.status == 200){
            setSeverity("success")
            setMessage(userss.message)
            setOpenSnack(true)
        }else if(userss.status == 400){
            setSeverity("error")
            setMessage(userss.message)
            setOpenSnack(true)
        }else if(userss.status == 206){
            setSeverity("warning")
            setMessage(userss.message)
            setOpenSnack(true)
        }
    },[userss])

    const { register, handleSubmit, setError, formState: { errors } } = useForm(
        { 
          mode: "all",
          defaultValues: {},
          criteriaMode: "all",
          shouldFocusError: true,
        });

    const handleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
        };  
          
    const [form, setForm] = useState({
        id: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        mobile: ""
    })

    const formHandler = (e) => {
        e.preventDefault();
        if(errors == true){
            return;
        }
       
        dispatch(updateAdminProfile(form))
        setIsProfileOpen(false)
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatch(disableSnackbar())
    }

    useEffect(()=>{
        setForm({
            id: userData?.adminData?._id,
            name: userData?.adminData?.name,
            email: userData?.adminData?.email,
            gender: userData?.adminData?.gender,
            mobile: userData?.adminData?.mobile_number,
            dob: userData?.adminData?.dob
        })
    },[userData.adminData])
    


    const updateProfile = (e) => {

    }

    return(
        <>
            <Modal 
                show={isProfileOpen} 
                title="Profile"
                btnLeft='Save'
                btnRight='Cancel'
                formId={'profile-form'}
                onCancel={()=>{
                    setForm({...form})
                    setIsProfileOpen(false)
                }} 
                onClose={()=>{
                    setForm({...form})
                    setIsProfileOpen(false)
                }}
            >
                <div className="w-8/12 ml-5">
                    <form id={'profile-form'}
                        onSubmit={formHandler}
                    >
                        <div className='mb-3 mt-2'>
                            <TextField
                                    label="Name"
                                    name="name"
                                    id="name"
                                    {...register("name", {required: true })}
                                    placeholder="Please enter Name"
                                    // color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        height: '100%'
                                        }}
                                        onChange={(e)=>handleChange(e)}
                                    value={form.name}
                                    variant="outlined"
                                />
                                {errors.name && <span>This field is required!</span>}
                        </div>
                        <div className='mb-3 mt-2'>
                            <TextField
                                    label="Email"
                                    name="email"
                                    id="email"
                                    {...register("email", {required: true })}
                                    placeholder="Please enter email"
                                    // color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        height: '100%'
                                        }}
                                        onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                    value={form.email}
                                    variant="outlined"
                                />
                                {errors.email && <span>This field is required!</span>}
                        </div>
                        <div className='mb-3 mt-2'>
                            <TextField
                                    label="Gender"
                                    name="gender"
                                    id="gender"
                                    select
                                    {...register("gender", {required: true })}
                                    placeholder="Please Choose gender"
                                    // color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        height: '100%'
                                        }}
                                        onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                    value={form.gender}
                                    variant="outlined"
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                </TextField>
                                {errors.gender && <span>This field is required!</span>}
                        </div>
                        <div className='mb-3'>
                            <TextField
                                    label="DOB"
                                    name="dob"
                                    id="dob"
                                    {...register("dob", {required: true })}
                                    placeholder="Please enter Email"
                                    value={form.dob}
                                    color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        height: '100%'
                                        }}
                                        onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                    variant="outlined"
                                />
                                {errors.dob && <span>This field is required!</span>}
                        </div>
                        <div className='mb-3'>
                            <TextField
                                    label="Phone Number"
                                    name="mobile"
                                    id="mobile"
                                    {...register("mobile", {required: true })}
                                    placeholder="Please enter Password"
                                    value={form.mobile}
                                    color="primary"
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        height: '100%'
                                        }}
                                        onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                    variant="outlined"
                                />
                                {errors.mobile && <span>This field is required!</span>}
                        </div>
                    </form>
                </div>
            </Modal>
            <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
            </SnackBar>
        </>
    )
}

export default Profile