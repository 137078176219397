import React from 'react'

const Footer = () => {

    const date = new Date();
    const yearOfDate = date.getFullYear(); // 👉️ 2025


    return (
        <>

            <footer>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
                                <div className="copyright-text" style={{ textAlign: "center" }}>
                                    <p>Copyright &copy; {yearOfDate}, All Right Reserved <a href="javascript:void(0)">Findme</a></p>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/page/terms-&-conditions">Terms & Conditions </Link></li>
                                        <li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>


        </>
    )
}

export default Footer