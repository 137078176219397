import ChipInput from 'material-ui-chip-input'
 
// uncontrolled input
{/* <ChipInput
  defaultValue={['foo', 'bar']}
  onChange={(chips) => handleChange(chips)}
/> */}
 
// controlled input
const ChipsInput = ({chips,handleAddChip,handleDeleteChip, label, placeholder, disabled}) =>(
  <ChipInput
    value={chips}
    onAdd={(chip) => handleAddChip(chip)}
    onDelete={(chip, index) => handleDeleteChip(chip, index)}
    variant="outlined"
    placeholder={placeholder}
    label={label}
    disabled={disabled}
/>
)
export default ChipsInput