import { NOTIFICATION_REQUEST, NOTIFICATION_SUCCESS, NOTIFICATION_FAILURE } from '../actions/sendNotificationAction'
import { DISABLE_SNACKBAR } from '../actions/sendNotificationAction'

const initialState = {
        isLoading: false,
        message: '',
        data: '',
        status: 0
}

const notificationReducer = (state = initialState, action) => {
    switch(action.type){
        case NOTIFICATION_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case NOTIFICATION_SUCCESS:
            return{
                ...state,
                data: action.payload.notifications,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case NOTIFICATION_FAILURE:
            return{
                ...state,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case DISABLE_SNACKBAR:
            return{
                ...state,
                status: 0
            }
        default: 
            return state
    }
}

export default notificationReducer;
