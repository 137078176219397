import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
export const APP_SETTINGS_REQUEST = 'APP_SETTINGS_REQUEST'
export const APP_SETTINGS_SUCCESS = 'APP_SETTINGS_SUCCESS'
export const APP_SETTINGS_FAILURE = 'APP_SETTINGS_FAILURE'
export const UPDATE_APP_SETTINGS_REQUEST = 'UPDATE_APP_SETTINGS_REQUEST'
export const UPDATE_APP_SETTINGS_SUCCESS = 'UPDATE_APP_SETTINGS_SUCCESS'
export const UPDATE_APP_SETTINGS_FAILURE = 'UPDATE_APP_SETTINGS_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'


const BaseUrl = process.env.REACT_APP_BASE_URL

export const updateAppSettings = (formData) => {
    return function (dispatch) {
        dispatch(updateAppSettingsRequest());
        axiosRequest.post(`updateAppSettings`, { formData: formData })
            .then(response => {
                // response.data.status = 0;
                console.log('update response',response)
                dispatch(updateAppSettingsSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateAppSettingsFailure(error))
            })
    }
}
export const getAppSettings = (formData) => {
    return function (dispatch) {
        dispatch(appSettingsRequest());
        axiosRequest.get(`getAppSettings`)
            .then(response => {
                // response.data.status = 0;
                dispatch(appSettingsSuccess(response.data))
            })
            .catch(error => {
                dispatch(appSettingsFailure(error))
            })
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}
export const appSettingsRequest = () => {
    return {
        type: APP_SETTINGS_REQUEST,
    }
}

export const appSettingsSuccess = (data) => {
    console.log('hello data',data)
    return {
        type: APP_SETTINGS_SUCCESS,
        payload: data,
    }
}

export const appSettingsFailure = (data) => {
    return {
        type: APP_SETTINGS_FAILURE,
        payload: data
    }
}

export const updateAppSettingsRequest = () => {
    return {
        type: UPDATE_APP_SETTINGS_REQUEST,
    }
}

export const updateAppSettingsSuccess = (data) => {
    console.log('hello data',data)
    return {
        type: UPDATE_APP_SETTINGS_SUCCESS,
        payload: data,
    }
}

export const updateAppSettingsFailure = (data) => {
    return {
        type: UPDATE_APP_SETTINGS_FAILURE,
        payload: data
    }
}
