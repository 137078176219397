import axios from 'axios';

import axiosRequest from '../../helper/axiosRequest'
const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'
export const USER_SUBSCRIPTIONS_LIST = 'USER_SUBSCRIPTIONS_LIST'

const BaseUrl = process.env.REACT_APP_BASE_URL


const fetchUsers = () => {
    return function (dispatch) {
        dispatch(fetchUsersRequest())
        axiosRequest.post('getUsers')
            .then(response => {
                response.data.status = 0
                dispatch(fetchUsersSuccess(response.data))
            })
            .catch(error => {
                dispatch(fetchUsersFailure(error.message))
            })
    }
}

export const updateAdminProfile = (form) => {
    return function (dispatch) {
        dispatch(fetchUsersRequest())
        axiosRequest.post('updateAdminProfile', { data: form })
            .then(response => {
                dispatch(fetchUsersSuccess(response.data))
            })
            .catch(error => {
                dispatch(fetchUsersFailure(error.message))
            })
    }
}


export const userSubscriptions = (form) => {
    return function (dispatch) {

        axiosRequest.post(`user-subscriptions-list`, { data: form })
            .then(response => {
                let { data } = response.data;
                dispatch({ type: USER_SUBSCRIPTIONS_LIST, payload: data })
            })
            .catch(error => {
                dispatch(fetchUsersFailure(error.message))
            })
    }
}

const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST,
    }
}

const fetchUsersSuccess = (users) => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users,
    }
}

const fetchUsersFailure = (error) => {
    return {
        type: FETCH_USERS_FAILURE,
        payload: error
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE, fetchUsersFailure, fetchUsersRequest, fetchUsersSuccess, fetchUsers }