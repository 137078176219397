// import React from "react";

// const Loader = () => (
//         <div className="home-content">
//             <div className="Userloader">
//                 Loader...
                // <div className="lds-spinner "><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
//             </div>
//         </div>
// )
// export default Loader;

// import React from 'react';
// import CircularProgress from '@mui/material/CircularProgress';

// const GlobalLoader = () => {
//   return (
//     <div className="global-loader-overlay">
//       <div className="global-loader">
//         <CircularProgress />
//         {/* <div className="lds-spinner "><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}

//       </div>
//     </div>
//   );
// };

// export default GlobalLoader;

import React from 'react';

const GlobalLoader = () => {
  return (
    <div className="global-loader-overlay">
      <div className="">
        <div className="lds-spinner "><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  );
};

export default GlobalLoader;

