import React, { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  getAppSettings,
  updateAppSettings,
  disableSnackbar,
} from "../store/actions/appSettingsAction";
import SnackBar from "./reusables/Snackbar.js";
// import {
//   getAppSettings,
//   updateAppSettings,
// } from "../../../Redux/Actions/AppSettingsAction";
import { toast } from "react-toastify";

const AppSettings = () => {
  const dispatch = useDispatch();
  const appSettingsData = useSelector((state) => state?.appSettings);
  const updateAppSettingsData = useSelector(
    (state) => state?.updatedAppSettings
  );
  console.log("updateAppSettingsData", updateAppSettingsData);

  const [loading, SetLoading] = useState(false);
  const appSettingSchema = yup.object().shape({
    androidVersion: yup
      .string()
      .required("Android Version is required")
      .max(100, "Name must be at most 100 characters long"),
    iosVersion: yup.string().required("Ios Version is required"),
    androidForceUpdate: yup
      .string()
      .required("Android Update is required")
      .max(100, "Name must be at most 100 characters long"),
    iosForceUpdate: yup.string().required("Ios update is required"),
    androidMaintenance: yup
      .string()
      .required("Android Maintenance is required")
      .max(100, "Name must be at most 100 characters long"),
    androidAppLink: yup.string().required("App link is required"),
    iosAppLink: yup
      .string()
      .required("Android Link is required")
      .max(100, "Name must be at most 100 characters long"),
    updateMessage: yup.string().required("Update Message is required"),
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(appSettingSchema),
  });
  const handleSubmitForm = (data) => {
    console.log("the main data", data);
    const formData = {
      id: data.id,
      android_version: data.androidVersion,
      ios_version: data.iosVersion,
      ios_app_link: data.iosAppLink,
      android_app_link: data.androidAppLink,
      android_maintenance: data.androidMaintenance,
      android_force_update: data.androidForceUpdate,
      ios_maintenance: data.iosMaintenance,
      ios_force_update: data.iosForceUpdate,
      update_message: data.updateMessage,
    };
    dispatch(updateAppSettings(formData));
  };

  const getAppSettingsData = async () => {
    SetLoading(true);
    const dataForm = {};
    await dispatch(getAppSettings(dataForm));
    SetLoading(false);
  };

  useEffect(() => {
    getAppSettingsData();
  }, []);

  useEffect(() => {
    if (appSettingsData.status == 200) {
      console.log("datatataassss", appSettingsData);
      setValue("androidVersion", appSettingsData?.data?.android_version);
      setValue("iosVersion", appSettingsData?.data?.ios_version);
      setValue(
        "androidForceUpdate",
        appSettingsData?.data?.android_force_update
      );
      setValue("iosForceUpdate", appSettingsData?.data?.ios_force_update);
      setValue(
        "androidMaintenance",
        appSettingsData?.data?.android_maintenance
      );
      setValue("iosMaintenance", appSettingsData?.data?.ios_maintenance);
      setValue("androidAppLink", appSettingsData?.data?.android_app_link);
      setValue("iosAppLink", appSettingsData?.data?.ios_app_link);
      setValue("updateMessage", appSettingsData?.data?.update_message);
    }
  }, [setValue, appSettingsData]);
  useEffect(() => {
    console.log("the newest data", updateAppSettingsData);
    if (updateAppSettingsData?.status == 200) {
      console.log("yes working fine");
      if (updateAppSettingsData?.status == 200) {
        setSeverity("success");
        setMessage(updateAppSettingsData?.message);
        setOpenSnack(true);
      } else if (updateAppSettingsData?.status == 400) {
        setSeverity("error");
        setMessage(updateAppSettingsData?.message);
        setOpenSnack(true);
      } else if (updateAppSettingsData?.status == 206) {
        setSeverity("warning");
        setMessage(updateAppSettingsData?.message);
        setOpenSnack(true);
      }
    }
  }, [updateAppSettingsData]);
  const handleSnackClose = () => {
    setOpenSnack(false);
    dispatch(disableSnackbar());
  };
  return (
    <Fragment>
      <SnackBar
        handleClose={handleSnackClose}
        message={message}
        open={openSnack}
        severity={severity}
      ></SnackBar>

      <div className="p-4">
        {/* <h1>
          Send Notification Form
        </h1> */}
        <div className="container-fluid">
          <div className="pb-3 pb-lg-4">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="page-title mb-0">App Setting</h4>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-4">
              <div className="row">
                <form
                  className="common-form-area-left-inner-form"
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <label htmlFor="androidVersion" className="form-label">
                        Android Version
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="androidVersion"
                        placeholder="Android Version"
                        {...register("androidVersion")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="androidVersion"
                        render={({ message }) => (
                          <p className="error" style={{ color: "red" }}>
                            {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="iosVersion" className="form-label">
                        iOS Version
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="iosVersion"
                        placeholder="iOS Version"
                        {...register("iosVersion")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="iosVersion"
                        render={({ message }) => (
                          <p className="error" style={{ color: "red" }}>
                            {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("androidForceUpdate")}
                        />
                        <label className="form-check-label">
                          Android Force Update
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("iosForceUpdate")}
                        />
                        <label className="form-check-label">
                          iOS Force Update
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("androidMaintenance")}
                        />
                        <label className="form-check-label">
                          Android Maintenance
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...register("iosMaintenance")}
                        />
                        <label className="form-check-label">
                          iOS Maintenance
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="androidAppLink" className="form-label">
                        Android App Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="androidAppLink"
                        {...register("androidAppLink")}
                        placeholder="Android App Link"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="androidAppLink"
                        render={({ message }) => (
                          <p className="error" style={{ color: "red" }}>
                            {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="iosAppLink" className="form-label">
                        iOS App Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="iosAppLink"
                        placeholder="IOS App Link"
                        {...register("iosAppLink")}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="iosAppLink"
                        render={({ message }) => (
                          <p className="error" style={{ color: "red" }}>
                            {message}
                          </p>
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="updateMessage" className="form-label">
                        Update Message
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="updateMessage"
                        {...register("updateMessage")}
                        placeholder="Update Message"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="updateMessage"
                        render={({ message }) => (
                          <p className="error" style={{ color: "red" }}>
                            {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn mt-5"
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      border: "2px solid blue",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="layout-wrapper">
        <div className="page-content">
        </div>
      </div> */}
    </Fragment>
  );
};

export default AppSettings;
