import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const ConfirmDialog = (props) => {
    let { open, onConfirm, onCancel, title, content } = props;

    title = title ? title : "Delete Item";
    content = content ? content : "Are you sure you want to delete this item?";
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog"> {title} </DialogTitle>
            <DialogContent> {content} </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onCancel} color="secondary">
                    No
                </Button>
                <Button variant="contained" onClick={onConfirm}
                    color="default">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;