import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE, DISABLE_SNACKBAR, USER_SUBSCRIPTIONS_LIST } from "../actions/usersActions";
const initialState = {
    loading: true,
    users: [],
    userSubscriptions: [],
    error: '',
    message: '',
    status: 0,
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_USERS_SUCCESS:
            return {
                loading: false,
                users: action.payload.dataOfUsers,
                status: action.payload.status,
                message: action.payload.message,
            }
        case FETCH_USERS_FAILURE:
            return {
                loading: false,
                users: [],
                error: action.payload,
                status: action.payload.status,
                message: action.payload.message,
            }
        case USER_SUBSCRIPTIONS_LIST:
            return {
                ...state,
                userSubscriptions: action.payload, 
                loading: false
            }

        case DISABLE_SNACKBAR:
            return {
                ...state,
                status: 0
            }
        default: return state
    }
}

export default userReducer;
