import {useSelector, useDispatch} from 'react-redux';
import * as React from 'react';
import { useEffect, useState }  from 'react';
import {fetchUsers} from '../store/actions/usersActions';
import CircularProgress from '@mui/material/CircularProgress';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useNavigate } from "react-router-dom";


function Dashboard(){

  const usersState = useSelector(state => state.users)
  const dispatchReq = useDispatch()

  useEffect(()=>{
    dispatchReq(fetchUsers())
  },[])

  let navigate = useNavigate(); 
  const routeChange = (data) =>{ 
    let path = `${data}`; 
    navigate(path);
  }

  return(
        <div className="home-content">
          <div className="overview-boxes">
            <div onClick={()=>routeChange('users')} className="box">
              <div className="right-side">
                <div className="box-topic">Total Users</div>
                {usersState?.loading ? <><CircularProgress color="primary" /></> : 
                <div className="number">{usersState?.users?.length}</div>
                }
              </div>
              <i className='bx bx-user cart'></i>
            </div>
            <div onClick={()=>routeChange('users')} className="box">
              <div className="right-side">
                <div className="box-topic">Active Users</div>
                {usersState?.loading ? <><CircularProgress color="primary" /></> : 
                <div className="number">{usersState?.users[0]?.activeUsers}</div>
                }
              </div>
              <i className='bx bx-user cart'></i>
            </div>
            <div onClick={()=>routeChange('reports')} className="box">
              <div className="right-side">
                <div className="box-topic">Reports</div>
                {usersState?.loading ? <><CircularProgress color="primary" /></> : 
                <div className="number">{usersState?.users[0]?.reportedUsersLength}</div>
                }
              </div>
              <i className='bx bx-block cart2'></i>
            </div>
            {/* <div className="box">
              <div className="right-side">
                <div className="box-topic">Total Pages</div>
                <div className="number">38,876</div>
                <div className="indicator">
                  <i className='bx bx-up-arrow-alt'></i>
                  <span className="text">Up from yesterday</span>
                </div>
              </div>
              <i className='bx bxs-cart-add cart two' ></i>
            </div>
          </div>
          <div className="sales-boxes">
            <div className="recent-sales box">
              <div className="title">Recent Sales</div>
              <div className="sales-details">
                <ul className="details">
                  <li className="topic">Date</li>
                  <li><a href="#">02 Jan 2021</a></li>
                </ul>
                <ul className="details">
                  <li className="topic">Customer</li>
                  <li><a href="#">Alex Doe</a></li>
                </ul>
                <ul className="details">
                  <li className="topic">Sales</li>
                  <li><a href="#">Delivered</a></li>
                </ul>
                <ul className="details">
                  <li className="topic">Total</li>
                  <li><a href="#">$204.98</a></li>
                </ul>
              </div>
              <div className="button">
                <a href="#">See All</a>
              </div>
            </div>
            <div className="top-sales box">
              <div className="title">Top Seling Product</div>
              <ul className="top-sales-details">
                <li>
                  <a href="#">
                    <img src={require("../images/sunglasses.jpg") } alt="" />
                    <span className="product">Vuitton Sunglasses</span>
                  </a>
                  <span className="price">$1107</span>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
    )
}
export default Dashboard