import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST'
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS'
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'
const BaseUrl = process.env.REACT_APP_BASE_URL

export const fetchSubscriptions = () => {
    return function (dispatch) {
        dispatch(SubscriptionRequest);
        axiosRequest.post(`get-subscription-plan`)
            .then(response => {
                response.data.status = 0;
                // console.log('response', response.data)
                dispatch(SubscriptionSuccess(response.data))
            })
            .catch(error => {
                dispatch(SubscriptionFailure(error))
            })
    }
}


export const createSubscription = (data) => {
    return function (dispatch) {
        dispatch(SubscriptionRequest());
        axiosRequest.post(`create-subscription-plan`, { planName: data.planName, price: data.price, Duration: data.Duration })
            .then(response => {
                dispatch(SubscriptionSuccess(response.data))
            })
            .catch(error => {
                dispatch(SubscriptionFailure(error))
            })
    }
}
export const updateSubscription = (data) => {
    return function (dispatch) {
        dispatch(SubscriptionRequest());
        axiosRequest.post(`update-subscription-field`, { subscription_id: data.id, planName: data.planName, price: data.price, Duration: data.Duration })
            .then(response => {
                dispatch(SubscriptionSuccess(response.data))
            })
            .catch(error => {
                dispatch(SubscriptionFailure(error))
            })
    }
}
export const deleteSubscription = (id) => {
    return function (dispatch) {
        dispatch(SubscriptionRequest());
        axiosRequest.post(`delete-subscription-field`, { subscription_id: id })
            .then(response => {
                dispatch(SubscriptionSuccess(response.data))
            })
            .catch(error => {
                dispatch(SubscriptionFailure(error))
            })
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export const SubscriptionRequest = () => {
    return {
        type: SUBSCRIPTION_REQUEST,
    }
}

export const SubscriptionSuccess = (data) => {
    return {
        type: SUBSCRIPTION_SUCCESS,
        payload: data,
    }
}

export const SubscriptionFailure = (data) => {
    return {
        type: SUBSCRIPTION_FAILURE,
        payload: data
    }
}
