import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILURE = 'REPORT_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'
const BaseUrl = process.env.REACT_APP_BASE_URL

export const fetchReports = () => {
    return function (dispatch) {
        dispatch(reportRequest());
        axiosRequest.post(`get-reported-users`)
            .then(response => {
                response.data.status = 0;
                dispatch(reportSuccess(response.data))
            })
            .catch(error => {
                dispatch(reportFailure(error))
            })
    }
}

export const resolveReport = (data) => {
    return function (dispatch) {
        dispatch(reportRequest());
        axiosRequest.post(`resolve-report`, { _id: data?._id, status: "Resolved", reportedUserId: data?.reportedUserId, userStatus: data?.reportedUserStatus })
            .then(response => {
                console.log('response', response.data)
                dispatch(reportSuccess(response.data))
            })
            .catch(error => {
                dispatch(reportFailure(error))
            })
    }
}

export const delete_report = (id) => {
    return function (dispatch) {
        dispatch(reportRequest());
        axiosRequest.post(`delete-reported-users`, { report_id: id })
            .then(response => {
                dispatch(reportSuccess(response.data))
            })
            .catch(error => {
                dispatch(reportFailure(error))
            })
    }
}

// export const changeFieldStatus = (Id, Status) => {
//     return function (dispatch,getState) {
//         dispatch(fieldRequest());
//         axiosRequest.post(`update-field-status`,{field_id: Id, status: Status})
//         .then(response => {
//             dispatch(fieldSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(fieldFailure(error))
//         })
//     }
// }

// export const createField = (data) => {
//     return function(dispatch){
//         dispatch(fieldRequest());
//         axiosRequest.post(`createField`, {title:data.Title, status:data.Status, type:data.Type, value:data.Value, header:data.Header})
//         .then(response => {
//             console.log('response',response.data)
//             dispatch(fieldSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(fieldFailure(error))
//         })
//     }
// }

// export const delete_field = (id) => {
//     return function(dispatch){
//         dispatch(fieldRequest());
//         axiosRequest.post(`delete-field`, {field_id: id})
//         .then(response => {
//             dispatch(fieldSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(fieldFailure(error))
//         })
//     }
// }

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export const reportRequest = () => {
    return {
        type: REPORT_REQUEST,
    }
}

export const reportSuccess = (data) => {
    return {
        type: REPORT_SUCCESS,
        payload: data,
    }
}

export const reportFailure = (data) => {
    return {
        type: REPORT_FAILURE,
        payload: data
    }
}
