import { SAFETY_CONTENT_LIST, SAFETY_CONTENT_LOADING } from "../actions/safetyContent";


const initialState = {
    loading: true,
    list: [],
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAFETY_CONTENT_LIST:
            return {
                ...state,
                list: action.payload,
                loading: false,
            }

        case SAFETY_CONTENT_LOADING:
            return {
                ...state,
                loading: action.payload
            }

        default: return state
    }
}

export default userReducer;
