import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPages,
  createPage,
  deletePage,
  updatePageAction,
} from "../store/actions/pagesActions";
import DataTable from "./reusables/DataTable";
import Modal from "./reusables/Modal";
import SnackBar from "./reusables/Snackbar.js";
import Loader from "./reusables/Loader.js";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import BodyTextEditor from "../components/reusables/BodyTextEditor";
import ConfirmDialog from "./reusables/ConfirmDialog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function Pages() {
  const pagesState = useSelector((state) => state?.pages);
  const dispatchReq = useDispatch();
  const [isCreatingPage, setIsCreatingPage] = useState(false);
  const [isEditingPage, setIsEditingPage] = useState(false);
  const [deleteDataId, setDeleteDataId] = useState({});

  const [pageSize, setPageSize] = useState(10);
  const [content, setContent] = useState({
    bodyText: "",
  });

  const changePaginationSize = (no) => {
    setPageSize(no);
  };
  const [form, setForm] = useState({});

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleDescriptionChange = (event,editor) => {
    console.log('i am called------->',editor)
    const dataEditor = editor.getData();
    console.log("dataas", dataEditor);
    setForm((prevData) => ({
      ...prevData,
      detail: dataEditor,
    }));
  };

  useEffect(() => {
    dispatchReq(fetchPages());
  }, []);


  const edit_page = (data) => {
    setIsCreatingPage(true);
    setForm({
      title: data.title,
      detail: data.detail,
      id: data._id,
    });
  };

  const updatePage = async (e) => {};

  const delete_page = async (page) => {
    setDeleteDataId(page);
  };

  const deleteItemConfirmation = () => {
    setOpen(true);
    dispatchReq(deletePage(deleteDataId._id)).then((v) => {
      dispatchReq(fetchPages());
    });
    setDeleteDataId({});
  };

  const createPageFunc = async (e) => {
    if (!["", null, undefined].includes(form.id)) {
      setIsCreatingPage(false);
      setOpen(false);
      dispatchReq(updatePageAction(form)).then((v) => {
        dispatchReq(fetchPages());
      });

      return;
    }

    setIsCreatingPage(false);
    setOpen(true);
    dispatchReq(createPage(form)).then((v) => {
      dispatchReq(fetchPages());
    });
  };

  const [columnDefs] = useState([
    { field: "sn", headerName: "S.No", Width: 50, minWidth: 50, maxWidth: 50 },
    { field: "_id", hide: true, minWidth: 10 },
    {
      field: "title",
      headerName: "Title",
      sortable: true,
      minWidth: 150,
      maxWidth: 250,
    },
    {
      field: "detail",
      headerName: "Detail",
      sortable: true,
      minWidth: 260,
      maxWidth: 300,
    },
    {
      field: "Action",
      headerName: "Action",
      headerClass: "new-class",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <div className="justify-end">
          <Button
            onClick={() => {
              edit_page(params?.row);
            }}
            sx={{ marginRight: 1 }}
            style={{ width: "100px" }}
            variant="contained"
            color="primary"
            startIcon={<ModeEditOutlinedIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => delete_page(params?.row)}
            style={{ width: "100px" }}
            variant="contained"
            color="error"
            startIcon={<DeleteOutlinedIcon />}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]);

  if (pagesState.isLoading) {
    return (
      <div className="home-content">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="home-content">
        <ConfirmDialog
          open={Object.keys(deleteDataId).length > 0 ? true : false}
          onCancel={() => setDeleteDataId({})}
          onConfirm={deleteItemConfirmation}
        />
        {
          <SnackBar
            handleClose={handleClose}
            message={pagesState.message}
            open={open}
            severity="success"
          />
        }

        <Modal
          title={isCreatingPage ? "Create Page" : "Edit Page"}
          btnLeft="Save"
          btnRight="Cancel"
          formId="create-form"
          onCancel={() => setIsCreatingPage(false)}
          onClose={() => setIsCreatingPage(false)}
          show={isCreatingPage}
        >
          <div className=" ml-5 ">
            <form
              id="create-form"
              onSubmit={(e) => {
                e.preventDefault();
                createPageFunc(e);
              }}
            >
              <div className="mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  onChange={(e) =>
                    setForm({ ...form, [e.target.name]: e.target.value })
                  }
                  className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                  name="title"
                  type="string"
                  placeholder="Title"
                  value={form?.title}
                />
              </div>
              <div
                className="mb-1 mt-2"
                style={{ float: "left", width: "80%" }}
              >
                <label
                  className="block text-gray-700 text-sm font-bold"
                  htmlFor="detail"
                >
                  Detail
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={form?.detail || ""}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={handleDescriptionChange}
                />
                {/* <textarea onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline" value={form?.detail} name="detail" type="string" placeholder="Detail" style={{ width: '100%', maxWidth: '100%', height: '150px' }} /> */}
              </div>
            </form>
          </div>
        </Modal>

        <Button
          onClick={() => {
            setIsCreatingPage(true);
            setForm({});
          }}
          style={{ width: "100px" }}
          sx={{ marginBottom: 1, marginLeft: 2 }}
          variant="contained"
          color="success"
          endIcon={<AddCircleOutlineIcon />}
        >
          Create
        </Button>
        <div className="">
          {pagesState?.data?.pages === undefined ? (
            ""
          ) : (
            <DataTable
              height={500}
              width="100%"
              rows={pagesState?.data?.pages}
              columns={columnDefs}
              pageSize={pageSize}
              setPageSize={(data) => changePaginationSize(data)}
              checkboxSelection={false}
              pagination={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableDensitySelector={true}
              // showQuickFilter={false}
            />
          )}
        </div>
        {/* <div style={{ width: '500px', height: '400px' }}>
                    <BodyTextEditor
                        value={content?.bodyText}
                        setValue={bodyText => setContent({ ...content, bodyText })}
                    />
                </div> */}
      </div>
    );
  }
}
export default Pages;
