import * as React from 'react'
import { useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux';
import { fetchSubscriptions, createSubscription, updateSubscription, deleteSubscription, disableSnackbar} from '../store/actions/subscriptionActions';
import DataTable from './reusables/DataTable';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from './reusables/Modal';
import SnackBar from './reusables/Snackbar.js';
import Skeleton from '@mui/material/Skeleton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Switch from '@mui/material/Switch';
import ChipsInput from './reusables/ChipInput';
import Checkbox from '@mui/material/Checkbox';
import Loader from './reusables/Loader.js';
import FormControlLabel from '@mui/material/FormControlLabel';

const Subscription = () => {
    const subscriptionState = useSelector((store)=> store.subscription)
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10)
    const [openSnack, setOpenSnack]= useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")
    const [isCreating, setIsCreating] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [form, setForm] = useState({
        id: '',
        planName: '',
        price: 1,
        Duration: '',
    })

    useEffect(()=>{
        if(subscriptionState.status == 200){
            setSeverity("success")
            setMessage(subscriptionState.message)
            setOpenSnack(true)
        }else if(subscriptionState.status == 400){
            setSeverity("error")
            setMessage(subscriptionState.message)
            setOpenSnack(true)
        }else if(subscriptionState.status == 206){
            setSeverity("warning")
            setMessage(subscriptionState.message)
            setOpenSnack(true)
        }
    },[subscriptionState])

    const changePaginationSize = (data) =>{

    }

    const edit_subscription = (data) => {
        setForm({
            id: data._id,
            planName: data.planName,
            price: data.price,
            Duration: data.Duration,
        })
        setIsEditing(true)
    }

    const delete_subscription_func = (data) => {

        console.log('delete fun')
        return ;

        dispatch(deleteSubscription(data._id))
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatch(disableSnackbar())
    }
    const create_subscription_func = async (e) => {
        setIsCreating(false)
        dispatch(createSubscription(form))
    }

    const update_subscription_func = (e) => {
        setIsEditing(false)
        dispatch(updateSubscription(form))
    }

    useEffect(()=>{
        dispatch(fetchSubscriptions())
    },[])

    const [columnDefs] = useState([
        { field: 'sn', headerName: 'S.No', Width: 50, minWidth:50, maxWidth: 50},
        { field: '_id', hide: true,},
        { field: 'planName', headerName:'Plan Name',sortable: true, minWidth: 150, maxWidth: 200},
        { field: 'price', headerName:'Price ($)',sortable: true, minWidth: 100, maxWidth: 150},
        { field: 'Duration', headerName:'Duration',sortable: true, minWidth: 100, maxWidth: 150},
        { field:'Action', headerName: "Action", headerClass: 'new-class', width: 250, sortable: false,
            renderCell: (params)=>(
                <div className='justify-end'>
                    <Button onClick={()=>edit_subscription(params?.row)} sx={{ marginRight: 1 }} style={{width: '100px'}} variant="contained" color='primary' startIcon={<ModeEditOutlinedIcon />}>
                        Edit
                    </Button>
                    <Button onClick={()=>delete_subscription_func(params?.row)} style={{width: '100px'}} variant="contained" color='error' startIcon={<DeleteOutlinedIcon />}>
                        Delete
                    </Button>
                </div>
            )
        }
        ])

    return(   
            fetchSubscriptions.isLoading ? 
            <div className='home-content'>
                <Loader />
            </div>
            : <div className='home-content'>
                <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
                </SnackBar>
                <Modal 
                    show={isCreating || isEditing} 
                    title={isCreating ? "Create Subscription" : isEditing ? "Edit Subscription" : ""}
                    btnLeft='Save'
                    btnRight='Cancel'
                    formId={isCreating ? 'create-form' : isEditing ? 'edit-form' : ""}
                    onCancel={()=>{
                        setForm({...form})
                        setIsCreating(false)
                        setIsEditing(false)
                    }} 
                    onClose={()=>{
                        setForm({...form})
                        setIsCreating(false)
                        setIsEditing(false)
                    }}
                >
                    <div className="w-8/12 ml-5">
                        <form id={isCreating ? 'create-form' : 'edit-form'}  onSubmit={(e)=>{
                            e.preventDefault()
                            if(isCreating){
                                create_subscription_func(e)
                            }else{
                                update_subscription_func(e)
                            }
                        }}>
                            <div className='mb-3 mt-2'>
                                <TextField
                                        label="Plan Name"
                                        name="planName"
                                        // {...register("lastname", {required: true })}
                                        placeholder="Please enter plan name"
                                        // color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                            }}
                                            onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                        // InputProps={{
                                        // startAdornment: (
                                        //     <InputAdornment position="start">
                                        //     <AccountCircle />
                                        //     </InputAdornment>
                                        // ),
                                        // }}
                                        value={form.planName}
                                        variant="outlined"
                                    />
                            </div>
                            <div className='mb-3'>
                                <TextField
                                        label="Price ($)"
                                        name="price"
                                        type={'number'}
                                        // {...register("lastname", {required: true })}
                                        placeholder="Please enter price"
                                        defaultValue={form.price}
                                        color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                            }}
                                            onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                        variant="outlined"
                                    />
                            </div>
                            <div className='mb-3'>
                                <TextField
                                        label="Duration"
                                        name="Duration"
                                        // {...register("lastname", {required: true })}
                                        placeholder="Please enter duration"
                                        value={form.Duration}
                                        color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                            }}
                                            onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                        // InputProps={{
                                        // startAdornment: (
                                        //     <InputAdornment position="start">
                                        //     <AccountCircle />
                                        //     </InputAdornment>
                                        // ),
                                        // }}
                                        variant="outlined"
                                    />
                            </div>
                        </form>
                    </div>
                </Modal>
                <Button onClick={()=>setIsCreating(true)} variant="contained" sx={{marginBottom: 1, marginLeft: 2}} style={{width: '100px'}} color='success' endIcon={<AddCircleOutlineIcon />}>
                    Create
                </Button>
                <DataTable 
                    height={500}
                    width='100%'
                    rows={subscriptionState?.data?.length > 0 ? subscriptionState?.data : []} 
                    columns={columnDefs}
                    pageSize={pageSize} 
                    setPageSize={(data)=>changePaginationSize(data)}
                    checkboxSelection={false}
                    pagination={true}
                    disableColumnFilter={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    // showQuickFilter={false} 
                />
            </div>
    )
}
export default Subscription;