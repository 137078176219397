import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
export const FIELDS_REQUEST = 'FIELDS_REQUEST'
export const FIELDS_SUCCESS = 'FIELDS_SUCCESS'
export const FIELDS_FAILURE = 'FIELDS_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'
const BaseUrl = process.env.REACT_APP_BASE_URL

export const fetchFields = () => {
    return function (dispatch) {
        dispatch(fieldRequest);
        axiosRequest.post(`get-extra-fields`)
            .then(response => {
                response.data.status = 0;
                dispatch(fieldSuccess(response.data))
            })
            .catch(error => {
                dispatch(fieldFailure(error))
            })
    }
}

export const changeFieldStatus = (Id, Status) => {
    return function (dispatch, getState) {
        dispatch(fieldRequest());
        axiosRequest.post(`update-field-status`, { field_id: Id, status: Status })
            .then(response => {
                dispatch(fieldSuccess(response.data))
            })
            .catch(error => {
                dispatch(fieldFailure(error))
            })
    }
}

export const createField = (data) => {
    return function (dispatch) {
        dispatch(fieldRequest());

        axiosRequest.post(`createField`, { title: data.Title, status: data.Status, type: data.Type, value: data.Value, header: data.Header })
            .then(response => {
                dispatch(fieldSuccess(response.data))
            })
            .catch(error => {
                dispatch(fieldFailure(error))
            })
    }
}
export const updateField = (data) => {
    return function (dispatch) {
        dispatch(fieldRequest());
        axiosRequest.post(`update-field`, { field_id: data.id, title: data.Title, status: data.Status, type: data.Type, value: data.Value, header: data.Header })
            .then(response => {
                dispatch(fieldSuccess(response.data))
            })
            .catch(error => {
                dispatch(fieldFailure(error))
            })
    }
}
export const delete_field = (id) => {
    return function (dispatch) {
        dispatch(fieldRequest());
        axiosRequest.post(`delete-field`, { field_id: id })
            .then(response => {
                dispatch(fieldSuccess(response.data))
            })
            .catch(error => {
                dispatch(fieldFailure(error))
            })
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export const fieldRequest = () => {
    return {
        type: FIELDS_REQUEST,
    }
}

export const fieldSuccess = (data) => {
    return {
        type: FIELDS_SUCCESS,
        payload: data,
    }
}

export const fieldFailure = (data) => {
    return {
        type: FIELDS_FAILURE,
        payload: data
    }
}
