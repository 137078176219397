import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE'
export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'
const BaseUrl = process.env.REACT_APP_BASE_URL

export const sendNotification = (formData) => {
    return function (dispatch) {
        dispatch(notificationRequest());
        axiosRequest.post(`send-notification`, { formData: formData })
            .then(response => {
                // response.data.status = 0;
                dispatch(notificationSuccess(response.data))
            })
            .catch(error => {
                dispatch(notificationFailure(error))
            })
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export const notificationRequest = () => {
    return {
        type: NOTIFICATION_REQUEST,
    }
}

export const notificationSuccess = (data) => {
    return {
        type: NOTIFICATION_SUCCESS,
        payload: data,
    }
}

export const notificationFailure = (data) => {
    return {
        type: NOTIFICATION_FAILURE,
        payload: data
    }
}
