import { APP_SETTINGS_REQUEST, APP_SETTINGS_SUCCESS, APP_SETTINGS_FAILURE,UPDATE_APP_SETTINGS_REQUEST,UPDATE_APP_SETTINGS_SUCCESS,UPDATE_APP_SETTINGS_FAILURE } from '../actions/appSettingsAction'
import { DISABLE_SNACKBAR } from '../actions/appSettingsAction'

const initialState = {
        isLoading: false,
        message: '',
        data: '',
        status: 0
}

const appSettingsReducer = (state = initialState, action) => {
    switch(action.type){
        case APP_SETTINGS_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case APP_SETTINGS_SUCCESS:
            return{
                ...state,
                data: action.payload.data,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case APP_SETTINGS_FAILURE:
            return{
                ...state,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case DISABLE_SNACKBAR:
            return{
                ...state,
                status: 0
            }
        default: 
            return state
    }
}
const appUpdateSettingsReducer = (state = initialState, action) => {
    console.log('hello new dataUpdate',action)
    switch(action.type){
        case UPDATE_APP_SETTINGS_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case UPDATE_APP_SETTINGS_SUCCESS:
            return{
                ...state,
                data: action?.payload?.data,
                message: action?.payload?.message,
                isLoading: false,
                status: action?.payload?.status
            }
        case UPDATE_APP_SETTINGS_FAILURE:
            return{
                ...state,
                message: action?.payload?.message,
                isLoading: false,
                status: action?.payload?.status
            }
        case DISABLE_SNACKBAR:
            return{
                ...state,
                status: 0
            }
        default: 
            return state
    }
}

export  {appSettingsReducer,appUpdateSettingsReducer};
