import { Card, Link, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { React, useEffect, useState } from 'react';
import { userLogin } from '../store/actions/loginActions';

let imageUrl = process.env.REACT_APP_BASE_IMAGES;
const LoginPage = () => {
  const userLoginState = useSelector(state => state.userlogin.is_admin)
  const dispatchReq = useDispatch()

  const [passwordStatus, setPasswordStatus] = useState(false)
  const { register, handleSubmit, setError, formState: { errors } } = useForm(
    {
      mode: "all",
      defaultValues: {},
      criteriaMode: "all",
      shouldFocusError: true,
    });

  const formHandler = (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value
    const data = { email: email, password: password }

    dispatchReq(userLogin(data))
  }

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
  }));

  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  const inputStyle = {
    width: 500,
    maxWidth: '100%',
    height: '100%',
    backgroundColor: '#e4f6f7'
  }

  return (
    <RootStyle>
      <SectionStyle>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Welcome Back
        </Typography>
        <img src={require("../images/illustration_login.png")} alt="login" />
      </SectionStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" gutterBottom>
            Sign in to FindMe
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

          <form onSubmit={formHandler}>
            <div className="w-8/12 ml-5">
              <div className="mb-3">
                <TextField
                  id="email"
                  label="Email"
                  name="email"
                  {...register("email", { required: true })}
                  // onChange={(e) => changeHandler(e)}
                  placeholder="Please enter email"
                  color="primary"
                  sx={inputStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> <SendIcon /> </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                {errors.email && <span>This field is required!</span>}
              </div>
              <div className="mb-3">
                <FormControl sx={inputStyle} variant="outlined" >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    {...register("password", { required: true })}
                    autoComplete="true"
                    type={passwordStatus ? 'text' : 'password'}
                    // onChange={(e) => changeHandler(e)}
                    startAdornment={<InputAdornment position="start"> <LockIcon /> </InputAdornment>}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setPasswordStatus(!passwordStatus)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {passwordStatus ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                {errors.password && <span>This field is required!</span>}
              </div>
              <div className="mb-3">
                <Button type="submit" variant="contained">Login</Button>
              </div>
            </div>
          </form>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>

          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
export default LoginPage