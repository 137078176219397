import { REPORT_REQUEST, REPORT_SUCCESS, REPORT_FAILURE} from '../actions/reportedUsersActions'
import { DISABLE_SNACKBAR } from '../actions/reportedUsersActions'

const initialState = {
        isLoading: false,
        message: '',
        data: '',
        status: 0
}

const reportReducer = (state = initialState, action) => {
    switch(action.type){
        case REPORT_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case REPORT_SUCCESS:
            return{
                ...state,
                data: action.payload.reports,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case REPORT_FAILURE:
            return{
                ...state,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case DISABLE_SNACKBAR:
            return{
                ...state,
                status: 0
            }
        default: 
            return state
    }
}

export default reportReducer;
