import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Select, MenuItem, FormControl, InputLabel, Grid, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PlanModal = ({ open, onClose, handlePlan,userId }) => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [planError, setPlanError] = useState(false); // Track whether the dropdown is required

  const planss = ['1 month', '3 months','6 months','12 months']; // Replace with your actual plan options
  // const plans = ['1 month', '3 months','6 months','12 months']; // Replace with your actual plan options
  const plans = ['1 Week','1 month', '3 months','6 months','12 months'];

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    // Reset the error state when the user selects a plan
    setPlanError(false);
  };

  const handleOKClick = () => {
    if (!selectedPlan) {
      // Show an error if no plan is selected
      setPlanError(true);
    } else {
      // Use the userId and selectedPlan to make your API request here
    //   console.log("selectedPlan",selectedPlan)
    //   console.log("userId",userId)
      handlePlan(selectedPlan)
      setSelectedPlan('') // Close the modal after the API request is made
    }
  };

  const modalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const contentStyle = {
    width: '300px',
    padding: '20px',
    height: '250px',
  };

  const labelStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'navy',
    marginBottom: '20px',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-content" style={{ ...modalStyle, ...contentStyle }}>
        <Button
          variant="text"
          color="primary"
          startIcon={<CloseIcon />}
          style={closeButtonStyle}
          onClick={onClose}
        />
        <h2 style={labelStyle}>Select a Plan</h2>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <FormControl fullWidth error={planError}>
              <InputLabel id="plan-label">Select Plan</InputLabel>
              <Select
                labelId="plan-label"
                id="plan-select"
                value={selectedPlan}
                onChange={handlePlanChange}
                fullWidth
                required // Make the dropdown required
              >
                {plans.map((plan, index) => (
                  <MenuItem key={index} value={plan}>
                    {plan}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{planError ? 'Plan is required' : ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleOKClick}>
              OK
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default PlanModal;
