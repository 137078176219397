import { CHECK_USERS_SUCCESS,CHECK_USERS_FAILURE, CHECK_USER_REQUEST } from "../actions/loginActions";
const initialState = {
    is_admin: false,
    error: '',
    isLoading: false,
    adminData: '',
}
const userLoginReducers = (state = initialState, action) => {
    switch(action.type){
        case CHECK_USER_REQUEST:
            return{
                ...state,
                isLoading: true,
            }
        case CHECK_USERS_SUCCESS:
            return{
                ...state,
                is_admin: action.payload,
                adminData: action.data,
                error: '',
                isLoading: false,
            }
        case CHECK_USERS_FAILURE:
            return{
                ...state,
                error: action.payload
            }
        default: return state
    }
}

export default userLoginReducers;
