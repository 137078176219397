import '../css/loginStyle.css';
import {useSelector, useDispatch} from 'react-redux';
import {React, useEffect, useState}  from 'react';
import {userLogin} from '../store/actions/loginActions';

function Login(){
    const userLoginState = useSelector(state => state.userlogin.is_admin)
    const dispatchReq = useDispatch()

    const formHandler = (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value 
        const password = document.getElementById('password').value
        const data = {email: email, password: password}
        dispatchReq(userLogin(data))
    }

    return(
        <div className="bg-gray-300 font-family:Roboto">
            <div className="w-full h-screen flex items-center justify-center">
                <form onSubmit={formHandler} className="w-full md:w-1/3 bg-white rounded-lg">
                    <div className="flex font-bold justify-center mt-6">
                        <img className="h-20 w-20"
                            src="https://raw.githubusercontent.com/sefyudem/Responsive-Login-Form/master/img/avatar.svg"/>
                    </div>
                    <h2 className="text-3xl text-center text-gray-700 mb-4">Login Form</h2>
                    <div className="px-12 pb-10">
                        <div className="w-full mb-2">
                            <div className="flex items-center">
                                <i className='ml-3 fill-current text-gray-400 text-xs z-10 fas fa-user'></i>
                                <input type='text' id="email" placeholder="Username"
                                    className="-mx-6 px-12 w-full border rounded py-2 text-gray-700 focus:outline-none" />
                            </div>
                        </div>
                        <div className="w-full mb-2">
                            <div className="flex items-center">
                                <i className='ml-3 fill-current text-gray-400 text-xs z-10 fas fa-lock'></i>
                                <input type='text' id="password" placeholder="Password"
                                    className="-mx-6 px-12 w-full border rounded py-2 text-gray-700 focus:outline-none" />
                            </div>
                        </div>
                        <a href="#" className="text-xs text-gray-500 float-right mb-4">Forgot Password?</a>
                        <button type="submit"
                            className="w-full py-2 rounded-full bg-green-600 text-gray-100  focus:outline-none">Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Login


// import React from 'react';
// import { useForm } from 'react-hook-form';

// export default function App() {
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const onSubmit = data => console.log(data);
//   console.log(errors);
  
//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <input type="text" placeholder="First name" {...register("First name", {required: true, maxLength: 80})} />
//       <input type="text" placeholder="Last name" {...register("Last name", {required: true, maxLength: 100})} />
//       <input type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
//       <input type="tel" placeholder="Mobile number" {...register("Mobile number", {required: true, minLength: 6, maxLength: 12})} />
//       <select {...register("Title", { required: true })}>
//         <option value="Mr">Mr</option>
//         <option value="Mrs">Mrs</option>
//         <option value="Miss">Miss</option>
//         <option value="Dr">Dr</option>
//       </select>

//       <input {...register("Developer", { required: true })} type="radio" value="Yes" />
//       <input {...register("Developer", { required: true })} type="radio" value="No" />

//       <input type="submit" />
//     </form>
//   );
// }