import React, {useState} from "react";
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';

const DataTable = ( {columns, rows, pageSize=10, 
    setPageSize, disableColumnFilter, disableColumnSelector,
    disableDensitySelector, showQuickFilter, Toolbar, height, width,
    checkboxSelection, pagination}) => {

    return(
        <div style={{ height: height, width: width }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                sx={{
                    border: 2,
                    borderColor: 'primary.main',
                    borderRadius: '16px',
                    boxShadow: 4,
                    margin: 1,
                    padding: 1
                }}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 40]}
                pagination={pagination}
                checkboxSelection={checkboxSelection}
                getRowId={(rows)=> rows._id}
                rowHeight={50}
                disableColumnFilter={disableColumnFilter}
                disableColumnSelector={disableColumnSelector}
                disableDensitySelector={disableDensitySelector}
                // disableToolbarButton={true}
                components={{ Toolbar: GridToolbarQuickFilter }}
                // componentsProps={{
                //     toolbar: {
                //       showQuickFilter: showQuickFilter,
                //       quickFilterProps: { debounceMs: 200 },
                //     },
                //   }}  
            />
        </div>
    )
}
export default DataTable;