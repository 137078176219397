import * as React from 'react'
import { useEffect, useState } from 'react'
import DataTable from './reusables/DataTable';
import axiosRequest from '../helper/axiosRequest';

let BASE_URL = process.env.REACT_APP_BASE_URL;

const DeletedAccounts = () => {

    const [list, setList] = useState([]);

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        const { data } = await axiosRequest.post(`delete-account-list`);
        setList(data.data)
    }

    const columnDefs = [
        { field: 'user_id', headerName: 'User ID', minWidth: 350 },
        { field: 'name', headerName: 'Name', minWidth: 350 },
        { field: 'email', headerName: 'Email', minWidth: 350 },
    ]

    return (
        <div className='home-content'>

            <DataTable
                height={550}
                width='100%'
                rows={list.length > 0 ? list : []}
                columns={columnDefs}
                pageSize={10}
                setPageSize={(data) => { }}
                checkboxSelection={false}
                pagination={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
            />
        </div>
    )
}
export default DeletedAccounts

