import * as React from 'react';
import { useState } from 'react';

const AdsManagement = () =>  {

    return (
        <div className='home-content'>
        </div>
    )
}

export default AdsManagement;