import axiosRequest from '../../helper/axiosRequest';
const BaseUrl = process.env.REACT_APP_BASE_URL
const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST'
const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS'
const CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE'

const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'

export const DISABLE_SNACKBAR = 'DISABLE_SNACKBAR'

const changeUserStatus = (data) => {
    return function (dispatch) {
        dispatch(changeUserStatusRequest())
        axiosRequest.post('changeUserStatus', data)
            .then(response => {
                dispatch(changeUserStatusSuccess(response.status, response.data.message))
            })
            .catch(error => {
                dispatch(changeUserStatusFailure(error.message))
            })
    }
}
const deleteUser = (data) => {
    return function (dispatch) {
        dispatch(deleteUserRequest())
        axiosRequest.post('admin-delete-user-account', data)
            .then(response => {
                if(response.data.status === 200){
                    dispatch(deleteUserSuccess(response.status, response.data.message))
                }else{
                    // dispatch(deleteUserSuccess(response.status, response.data.message))
                }
              
            })
            .catch(error => {
                dispatch(deleteUserFailure(error.message))
            })
    }
}
const subcribedUser = (data) => {
    return function (dispatch) {
        dispatch(deleteUserRequest())
        axiosRequest.post('admin-purchase-plan', data)
            .then(response => {
                if(response.data.status === 200){
                    dispatch(deleteUserSuccess(response.status, response.data.message))
                }else{
                    // dispatch(deleteUserSuccess(response.status, response.data.message))
                }
              
            })
            .catch(error => {
                dispatch(deleteUserFailure(error.message))
            })
    }
}


const changeUserStatusRequest = () => {
    return {
        type: CHANGE_USER_STATUS_REQUEST,
    }
}

const changeUserStatusSuccess = (status, message) => {
    return {
        type: CHANGE_USER_STATUS_SUCCESS,
        message: message,
        payload: status
    }
}

const changeUserStatusFailure = (error) => {
    return {
        type: CHANGE_USER_STATUS_FAILURE,
        payload: error
    }
}

const deleteUserRequest = () => {
    return {
        type:  USER_DELETE_REQUEST,
    }
}

const deleteUserSuccess = (status, message) => {
    return {
        type:  USER_DELETE_SUCCESS,
        message: message,
        payload: status
    }
}

const deleteUserFailure = (error) => {
    return {
        type:  USER_DELETE_FAILURE,
        payload: error
    }
}

export const disableSnackbar = () => {
    return {
        type: DISABLE_SNACKBAR
    }
}

export { CHANGE_USER_STATUS_REQUEST, CHANGE_USER_STATUS_SUCCESS, CHANGE_USER_STATUS_FAILURE, changeUserStatus, changeUserStatusFailure, changeUserStatusRequest, changeUserStatusSuccess , USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAILURE,deleteUser,subcribedUser, deleteUserFailure, deleteUserRequest, deleteUserSuccess}