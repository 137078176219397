import './App.css';
import './css/style.css';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, HashRouter } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from './components/dashboard';
import Users from './components/users';
import Login from './components/login';
import AdsManagement from './components/AdsManagement';
import Pages from './components/pages';
import Intro from './components/intro';

import Fields from './components/Fields';
import Reports from './components/Reports';
import { userAlreadyLogin } from './store/actions/loginActions';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import LoginPage from './components/LoginPage';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Profile from './components/Profile';
import Notification from './components/Notification';
import Subscription from './components/Subscriptions';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Loader from './components/reusables/Loader';
import ConfirmDialog from './components/reusables/ConfirmDialog';
import StaticPage from './pages/StaticPage';
import { Switch } from 'react-router-dom';
import SupportPage from './pages/SupportPage';
import PaidIcon from '@mui/icons-material/Paid';
import UserSubscriptions from './pages/user-subscriptions/list';
import AppSettings  from './components/AppSettings';
import DeletedAccounts from './components/DeletedAccounts';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


function App() {
  const userLoginState = useSelector(state => state.userlogin.is_admin)
  // const userLoginState = true

  const [anchorEl, setAnchorEl] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileClickHandler = () => {
    isProfileOpen ? setIsProfileOpen(false) : setIsProfileOpen(true)
  }

  const handleLogout = () => {
    setConfirmationModal(true)
  }

  const handleLogoutConfirm = () => {
    setConfirmationModal(false);
    localStorage.clear();
    localStorage.removeItem('accessToken')
    window.location.reload();
    // window.location.replace('http://findme.apptesting.co.in/')
  }


  const isLoading = useSelector(state => state.userlogin.isLoading)
  const dispatchReq = useDispatch()
  const [sidebarclass, setSidebarclass] = useState("sidebar")
  const [sidebarbtnclass, setSidebarbtnclass] = useState("bx bx-menu sidebarBtn")
  const [is_open, setIsopen] = useState(true)
  const buttonPress = () => {
    if (is_open) {
      setSidebarclass("sidebar active")
      setSidebarbtnclass("bx bx-menu-alt-right sidebarBtn")
      setIsopen(false)
    } else {
      setSidebarclass("sidebar")
      setSidebarbtnclass("bx bx-menu sidebarBtn")
      setIsopen(true)
    }
  }
  useEffect(() => {
    dispatchReq(userAlreadyLogin())
  }, [userLoginState, dispatchReq])

  if (isLoading === true) {
    return (
      <div className='home-content'>
        <Loader />
      </div>
    )
  } else {


    if (userLoginState === true) {
      return (
        <div>
          <ConfirmDialog
            title="Logout"
            content="Are you sure you want logout?"
            open={confirmationModal}
            onCancel={() => setConfirmationModal(false)}
            onConfirm={handleLogoutConfirm}
          />

          <HashRouter>
            <div className={sidebarclass}>
              <div className="logo-details">
                <i className='bx bxl-c-plus-plus'></i>
                <span className="logo_name">FindMe</span>
              </div>

              <ul className="nav-links">
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/">
                    <i className='bx bx-grid-alt' ></i>
                    <span className="links_name">Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/users">
                    <i><PeopleAltOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Users</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/pages">
                    <i><ImportContactsOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Pages</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/intro">
                    <i><ImportContactsOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Intro Page</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/fields">
                    <i><SaveAsOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Fields</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/ads">
                    <i><FeaturedVideoOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Ads</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/reports">
                    <i><FlagOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Reports</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/notifications">
                    <i><NotificationsActiveOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Notifications</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/user-subscriptions">
                    <i><PaidIcon fontSize='small' /></i>
                    <span className="links_name">User Subscriptions</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/app-settings">
                    <i><Settings fontSize='small' /></i>
                    <span className="links_name">App Settings</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink style={isActive => ({ class: isActive ? "active" : " " })} to="/deleted-accounts">
                    <i><PeopleAltOutlinedIcon fontSize='small' /></i>
                    <span className="links_name">Deleted Accounts</span>
                  </NavLink>
                </li>

                {/* <li>
                    <NavLink style={isActive => ({class: isActive ? "active" : " "})} to="/subscriptions">
                      <i><ShoppingCartIcon fontSize='small'/></i>
                      <span className="links_name">Subscriptions</span>
                    </NavLink>
                  </li> */}

                <li className="log_out">
                  <button onClick={handleLogout} style={{ color: 'white' }}>
                    <i className='bx bx-log-out'></i>
                    <span className="links_name">Log out </span>
                  </button>
                </li>
              </ul>
            </div>
            <section className="home-section">
              <Profile setIsProfileOpen={setIsProfileOpen} isProfileOpen={isProfileOpen} />
              <nav>
                <div className="sidebar-button">
                  <i className={sidebarbtnclass} onClick={buttonPress}></i>
                  {/* <span className="dashboard">Dashboard</span> */}
                </div>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="large"
                    sx={{ ml: 2, mr: 1 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar
                        src={require("./images/adminPic.png")}
                        sx={{ width: 52, height: 52 }}>M</Avatar>
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => profileClickHandler()}>
                    <Avatar >P</Avatar> Profile
                  </MenuItem>

                  <Divider />

                  <MenuItem onClick={() => {
                    localStorage.removeItem('accessToken')
                    window.location.reload()
                  }}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
                {/* <span className="admin_name">Admin Name</span>
                    <i className='bx bx-chevron-down' ></i> */}
              </nav>
              <Routes>
                {/* <Route path='/login' element={<LoginPage/>}></Route> */}
                <Route path='/' element={<Dashboard />}></Route>
                <Route path='/users' element={<Users />}></Route>
                <Route path='/pages' element={<Pages />}></Route>
                <Route path='/fields' element={<Fields />}></Route>
                <Route path='/ads' element={<AdsManagement />}></Route>
                <Route path='/reports' element={<Reports />}></Route>
                <Route path='/notifications' element={<Notification />}></Route>
                <Route path='/subscriptions' element={<Subscription />}></Route>
                <Route path='/user-subscriptions' element={<UserSubscriptions />}></Route>
                <Route path='/app-settings' element={<AppSettings />}></Route>

                <Route path='/deleted-accounts' element={<DeletedAccounts />}></Route>


                <Route path='/intro' element={<Intro />}></Route>

              </Routes>
            </section>
          </HashRouter>
        </div>
      )
    } else {
      return (
        <>
          <HashRouter>
            <Routes>

              <Route path='/' element={<LoginPage />}></Route>
              <Route path='/login' element={<LoginPage />}></Route>
              <Route path='/support' element={<SupportPage />}></Route>
              <Route path='/page/:slug' element={<StaticPage />} ></Route>

            </Routes>
          </HashRouter>
        </>
      )
    }
  }
}
export default App;
