import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchReports, resolveReport, disableSnackbar ,delete_report} from '../store/actions/reportedUsersActions'
import {changeUserStatus ,deleteUser ,subcribedUser} from '../store/actions/changeStatusActions';

import DataTable from './reusables/DataTable';
import { Button } from '@mui/material';
import SnackBar from './reusables/Snackbar.js';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Modal from './reusables/Modal';
import TextField from '@mui/material/TextField';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';



const Reports = () => {
    const reportsData = useSelector(store => store.reportedUsers)
    const dispatch = useDispatch()
    const [isDetailOpen, setIsDetailOpen] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [openSnack, setOpenSnack] = useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")
    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatch(disableSnackbar())
    }
    const [reportData, setReportData] = useState('')
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);



    useEffect(() => {
        if (reportsData.status == 200) {
            setSeverity("success")
            setMessage(reportsData.message)
            setOpenSnack(true)
        } else if (reportsData.status == 400) {
            setSeverity("error")
            setMessage(reportsData.message)
            setOpenSnack(true)
        } else if (reportsData.status == 206) {
            setSeverity("warning")
            setMessage(reportsData.message)
            setOpenSnack(true)
        }
    }, [reportsData])

    const report_detail = (data) => {
        setReportData(data)
        setIsDetailOpen(true)
    }

    const changePaginationSize = (data) => {

    }
    const resolve_report = (data) => {
        if (data.reportedUserStatus == true) {
            data.reportedUserStatus = false
        } else {
            data.reportedUserStatus = true
        }
        dispatch(resolveReport(data))
    }
    console.log(reportData)
    useEffect(() => {
        dispatch(fetchReports())
    }, [])


    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
      };
    
      const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setUserIdToDelete(null)
      };
    
      const handleDelete = () => {
        // Implement your delete logic here
     dispatch(delete_report(userIdToDelete))
    // dispatch(deleteUser({user_id: userIdToDelete}))
     dispatch(fetchReports())

     closeDeleteDialog();

       
      };


      const delete_fieldFunc = (data) => {
        openDeleteDialog()
        setUserIdToDelete(data._id)
        // setUserIdToDelete(data?.reportedUserId)

    }

    const [columnDefs] = useState([
        { field: 'sn', headerName: 'S.No', Width: 50, minWidth: 50, maxWidth: 50 },
        { field: '_id', hide: true, },
        { field: 'reportedByName', headerName: 'Reported By', sortable: true, minWidth: 150, maxWidth: 200 },
        { field: 'reportedUserName', headerName: 'Reported User', sortable: true, minWidth: 150, maxWidth: 200 },
        {
            field: 'type', headerName: 'Type', headerClass: 'new-class', width: 120, sortable: true,
            renderCell: (params) => (
                <div>
                    {params?.row?.type == "Soft" ? <p >{params?.row?.type}</p> : <p style={{ color: 'red' }}>{params?.row?.type}</p>}
                </div>
            )
        },
        { field: 'description', headerName: 'Description', sortable: true, minWidth: 170, maxWidth: 150 },
        {
            field: 'reportStatus', headerName: 'Status', headerClass: 'new-class', width: 120, sortable: false, description: 'Status of the report',
            renderCell: (params) => (
                <div>
                    {params?.row?.reportStatus == "Resolved" ? <p style={{ color: 'green' }}>{params?.row?.reportStatus}</p> : <p style={{ color: 'red' }}>{params?.row?.reportStatus}</p>}
                </div>
            )
        },
        {
            field: 'Action', width: 300, 
            renderCell: (params) => (
                <div className='justify-end'>
                    <Button onClick={() => resolve_report(params?.row)} style={styles.button1} variant="contained" color='primary'>
                        {params?.row?.reportedUserStatus ? 'Block User' : 'Unblock User'}
                    </Button>
                    <Button onClick={()=>delete_fieldFunc(params?.row)} style={{width: '100px' ,marginRight: 5}} variant="contained" color='error' startIcon={<DeleteOutlinedIcon />}>
                        Delete
                    </Button>
                    <Button onClick={() => report_detail(params?.row)} style={styles.buttonView} variant="contained" color='success' startIcon={<RemoveRedEyeOutlinedIcon />}>
                    </Button>

                   
                </div>
            )
        }
    ])

    return (
        <div className='home-content'>
            {
                <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
                </SnackBar>

            }
            {
                <Modal
                    show={isDetailOpen}
                    title="Report Details"
                    // btnLeft='Save'
                    // btnRight='Cancel'
                    // formId={'profile-form'}
                    // onCancel={()=>{
                    //     // setForm({...form,Type: 'Select'})
                    //     setIsDetailOpen(false)
                    // }} 
                    onClose={() => {
                        // setForm({...form,Type: 'Select'})
                        setIsDetailOpen(false)
                    }}
                >
                    <div className="w-8/12 ml-5 flex">
                        <div className='mb-3 mt-2'>
                            <TextField
                                label="ReportedBy"
                                name="ReportedBy"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    width: 200,
                                    maxWidth: '100%',
                                    height: '100%'
                                }}
                                value={reportData?.reportedByName}
                                variant="outlined"
                            />
                        </div>
                        <div className='mb-3 ml-5 mt-2'>
                            <TextField
                                label="ReportedUser"
                                name="ReportedUser"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    width: 200,
                                    maxWidth: '100%',
                                    height: '100%'
                                }}
                                value={reportData?.reportedUserName}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className="w-8/12 ml-5 flex">
                        <div className='mb-3 mt-2'>
                            <TextField
                                label="Type"
                                name="Type"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    width: 200,
                                    maxWidth: '100%',
                                    height: '100%'
                                }}
                                value={reportData?.type}
                                variant="outlined"
                            />
                        </div>
                        <div className='mb-3 ml-5 mt-2'>
                            <TextField
                                label="Status"
                                name="Status"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    width: 200,
                                    maxWidth: '100%',
                                    height: '100%'
                                }}
                                value={reportData?.reportStatus}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className="w-8/12 ml-5 flex">
                        <div className='mb-3 mt-2'>
                            <TextField
                                label="Description"
                                name="Description"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    width: 200,
                                    maxWidth: '100%',
                                    height: '100%'
                                }}
                                value={reportData?.description}
                                variant="outlined"
                            />
                        </div>
                    </div>
                </Modal>
            }
            <DataTable
                height={550}
                width='100%'
                rows={reportsData?.data?.length > 0 ? reportsData?.data : []}
                columns={columnDefs}
                pageSize={pageSize}
                setPageSize={(data) => changePaginationSize(data)}
                checkboxSelection={false}
                pagination={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
            // showQuickFilter={false} 
            />
             <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        handleClose={closeDeleteDialog}
        handleDelete={handleDelete}
        title={"Are you sure you want to delete this report?"}
      />
        </div>
    )
}
export default Reports


const styles = {
    button1: { width: '140px', marginRight: 5 },
    buttonView : { maxWidth: '40px', paddingRight: '5px', maxHeight: '36px', minWidth: '40px', minHeight: '36px' },
}