import { applyMiddleware, createStore, combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import changeStatusReducer from "./reducers/changeStatusReducer";
import userLoginReducers from "./reducers/userLoginReducer";
import { composeWithDevTools } from 'redux-devtools-extension'; // imp
import pagesReducer from "./reducers/pagesReducer";
import introReducer from "./reducers/introReducer";

import fieldsReducer from "./reducers/fieldsReducer";
import reportReducer from "./reducers/reportedUsersReducer";
import notificationReducer from "./reducers/notificationReducer";
import {appSettingsReducer,appUpdateSettingsReducer} from "./reducers/appSettingsReducer";
import SubscriptionReducer from "./reducers/subscriptionReducer";
import safetyContentReducer from "./reducers/safetyContent";
const thunkMiddleware = require('redux-thunk').default //allow action creator to return a function instead of a object

const rootReducer = combineReducers({  //combine reducers into 1 as store can have 1 reducer passed to it
    users: userReducer,    //when accessing cake state we need to use state.cake.
    userlogin: userLoginReducers,
    changeStatus: changeStatusReducer,
    pages: pagesReducer,
    intro: introReducer,
    fields: fieldsReducer,
    reportedUsers: reportReducer,
    notification: notificationReducer,
    appSettings:appSettingsReducer,
    updatedAppSettings:appUpdateSettingsReducer,
    subscription: SubscriptionReducer,
    safetyContent: safetyContentReducer,
})
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))

export default store;