import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
const CHECK_USERS_SUCCESS = 'CHECK_USERS_SUCCESS'
const CHECK_USERS_FAILURE = 'CHECK_USERS_FAILURE'
const CHECK_USER_ALREADY_LOGIN = 'CHECK_USER_ALREADY_LOGIN'
const CHECK_USER_REQUEST = 'CHECK_USER_REQUEST'
const BaseUrl = process.env.REACT_APP_BASE_URL

const userLogin = (data) => {
    return function (dispatch) {

        axiosRequest.post('checkUser', data).then(response => {

            if (response.data.status === false) {
                alert(response.data.message);
                return;
            }

            let { accessToken, user } = response.data;

            localStorage.setItem('accessToken', accessToken);
            delete user.password;
            delete user.dob;
            delete user.FcmToken;

            localStorage.setItem('user', JSON.stringify(user));
            dispatch(checkUserSuccess({ payload: response.data.status, data: response.data.user }))
        })
            .catch(error => {
                alert(error.message);
                dispatch(checkUserSuccess(error.message))
            })
    }
}
const checkUserRequest = () => {
    return {
        type: CHECK_USER_REQUEST
    }
}

const checkUserSuccess = ({ payload, data }) => {
    return {
        type: CHECK_USERS_SUCCESS,
        payload: payload,
        data: data
    }
}
const checkUserFailure = (error) => {
    return {
        type: CHECK_USERS_FAILURE,
        payload: error
    }
}

const userAlreadyLogin = () => {
    const accessToken = localStorage.getItem('accessToken')
    return function (dispatch) {
        dispatch(checkUserRequest())
        axios.post(BaseUrl + 'isAdmin', {}, { headers: { 'Token': accessToken, 'content-type': 'application/json' } })
            .then(response => {
                dispatch(checkUserSuccess({ payload: response.data.status, data: response.data.user }))
            })
            .catch(error => {
                dispatch(checkUserSuccess(error.message))
            })
        // dispatch(checkUserSuccess(true))
    }
}


export { CHECK_USERS_SUCCESS, CHECK_USERS_FAILURE, CHECK_USER_ALREADY_LOGIN, CHECK_USER_REQUEST, checkUserRequest, checkUserSuccess, checkUserFailure, userLogin, userAlreadyLogin }