import * as React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux';
import { fetchFields, changeFieldStatus, disableSnackbar, createField, updateField, delete_field } from '../store/actions/fieldsActions';
import DataTable from './reusables/DataTable';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from './reusables/Modal';
import SnackBar from './reusables/Snackbar.js';
import Skeleton from '@mui/material/Skeleton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Switch from '@mui/material/Switch';
import ChipsInput from './reusables/ChipInput';
import Checkbox from '@mui/material/Checkbox';
import Loader from './reusables/Loader.js';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';

import FormControlLabel from '@mui/material/FormControlLabel';

const Fields = () => {
    const fieldState = useSelector((store) => store.fields)
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10)
    const [openSnack, setOpenSnack] = useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")
    const [isCreating, setIsCreating] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [form, setForm] = useState({
        id: '',
        Header: 'Interests',
        Title: '',
        Type: 'String',
        Value: [],
        Status: true,
    })

    const [userIdToDelete, setUserIdToDelete] = useState(null);

    const change_field_status = async (data, e) => {
        dispatch(changeFieldStatus(data._id, e.target.checked))
    }

    useEffect(() => {
        if (fieldState.status == 200) {
            setSeverity("success")
            setMessage(fieldState.message)
            setOpenSnack(true)
        } else if (fieldState.status == 400) {
            setSeverity("error")
            setMessage(fieldState.message)
            setOpenSnack(true)
        } else if (fieldState.status == 206) {
            setSeverity("warning")
            setMessage(fieldState.message)
            setOpenSnack(true)
        }
    }, [fieldState])

    const changePaginationSize = (data) => {

    }

    const edit_field = (data) => {
        setForm({
            id: data._id,
            Header: data.header,
            Title: data.title,
            Type: data.type,
            Value: data.value,
            Status: data.status,
        })
        setIsEditing(true)
    }



    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatch(disableSnackbar())
    }
    const createFieldFunc = async (e) => {
        setIsCreating(false)
        dispatch(createField(form))
    }

    const updateFieldFunc = (e) => {
        setIsEditing(false)
        dispatch(updateField(form))
    }
    const handleDeleteChip = (e) => {
        const values = form.Value
        const newValues = values.filter((value) => {
            if (value !== e) {
                return value
            }
        })
        setForm({ ...form, Value: newValues })
    }

    useEffect(() => {
        dispatch(fetchFields())
    }, [])

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
      };
    
      const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setUserIdToDelete(null)
      };
    
      const handleDelete = () => {
        // Implement your delete logic here
        console.log('field deleted');
     dispatch(delete_field(userIdToDelete))
     closeDeleteDialog();

       
      };


      const delete_fieldFunc = (data) => {
        openDeleteDialog()
        setUserIdToDelete(data._id)
    }

    const [columnDefs] = useState([
        { field: 'sn', headerName: 'S.No', Width: 50, minWidth: 50, maxWidth: 50 },
        { field: '_id', hide: true, },
        { field: 'header', headerName: 'Header', sortable: true, minWidth: 150, maxWidth: 200 },
        { field: 'title', headerName: 'Title', sortable: true, minWidth: 100, maxWidth: 150 },
        { field: 'type', headerName: 'Type', sortable: true, minWidth: 100, maxWidth: 150 },
        { field: 'value', headerName: 'Value', sortable: true, minWidth: 250, maxWidth: 300 },
        {
            field: 'status', headerName: "Status", headerClass: 'new-class', width: 120, sortable: false, description: 'Activate or Deactivate a field',
            renderCell: (params) => (
                <div>
                    <Switch inputProps={{ 'aria-label': 'Switch demo' }} defaultChecked={params?.row?.status} onChange={(e) => change_field_status(params?.row, e)} />
                </div>
            )
        },
        {
            field: 'Action', headerName: "Action", headerClass: 'new-class', width: 250, sortable: false,
            renderCell: (params) => (
                <div className='justify-end'>
                    <Button onClick={() => edit_field(params?.row)} sx={{ marginRight: 1 }} style={{ width: '100px' }} variant="contained" color='primary' startIcon={<ModeEditOutlinedIcon />}>
                        Edit
                    </Button>
                    <Button onClick={()=>delete_fieldFunc(params?.row)} style={{width: '100px'}} variant="contained" color='error' startIcon={<DeleteOutlinedIcon />}>
                        Delete
                    </Button>
                    <div>


    </div>
                </div>
            )
        }
    ])




    return (
        (
            fieldState.isLoading ?
                <div className='home-content'>
                    <Loader />
                </div>
                : <div className='home-content'>
                    <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
                    </SnackBar>
                    <Modal
                        show={isCreating || isEditing}
                        title={isCreating ? "Create Field" : "Edit Field"}
                        btnLeft='Save'
                        btnRight='Cancel'
                        formId={isCreating ? 'create-form' : 'edit-form'}
                        onCancel={() => {
                            setForm({
                                id: '',
                                Header: 'Interests',
                                Title: '',
                                Type: 'String',
                                Value: [],
                                Status: true,
                            })
                            setIsCreating(false)
                            setIsEditing(false)
                            // window.location.reload()
                        }}
                        onClose={() => {
                            setForm({
                                id: '',
                                Header: 'Interests',
                                Title: '',
                                Type: 'String',
                                Value: [],
                                Status: true,
                            })
                            setIsCreating(false)
                            setIsEditing(false)
                            // window.location.reload()
                        }}
                    >
                        <div className="w-8/12 ml-5">
                            <form id={isCreating ? 'create-form' : 'edit-form'} onSubmit={(e) => {
                                e.preventDefault()
                                if (isCreating) {
                                    createFieldFunc(e)
                                } else {
                                    updateFieldFunc(e)
                                }
                            }}>
                                <div className='mb-3 mt-2'>
                                    <TextField
                                        label="Header"
                                        name="Header"
                                        select
                                        // {...register("lastname", {required: true })}
                                        placeholder="Please enter Header"
                                        // color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                        }}
                                        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                        // InputProps={{
                                        // startAdornment: (
                                        //     <InputAdornment position="start">
                                        //     <AccountCircle />
                                        //     </InputAdornment>
                                        // ),
                                        // }}
                                        value={form.Header}
                                        variant="outlined"
                                    >
                                        <MenuItem value="Interests">Interests</MenuItem>
                                        <MenuItem value="LifeDetails">LifeDetails</MenuItem>
                                        <MenuItem value="Specifics">Specifics</MenuItem>
                                    </TextField>
                                </div>
                                <div className='mb-3'>
                                    <TextField
                                        label="Title"
                                        name="Title"
                                        placeholder="Please enter Field Title"
                                        value={form.Title}
                                        color="primary"
                                        // disabled={isEditing ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: 500, maxWidth: '100%', height: '100%' }}
                                        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                        variant="outlined"
                                    />
                                </div>
                                <div className='mb-5'>
                                    <TextField
                                        name="Type"
                                        select
                                        label="Type of Field"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        //   }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '30px',
                                        }}
                                        value={form.Type}
                                        onChange={(e) => {
                                            setForm({ ...form, [e.target.name]: e.target.value })
                                            // console.log('form', form)
                                        }}
                                    >
                                        <MenuItem value="String">String</MenuItem>
                                        <MenuItem value="Select">Select</MenuItem>
                                    </TextField>
                                </div>
                                <div className='mb-2'>
                                    {form.Type == "String" ? <span>This Field is disabled!</span> : <span></span>}
                                    <ChipsInput
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        disabled={form.Type == "String" ? true : false} placeholder="Please Enter values" label="Field Values" chips={form.Type == "String" ? '' : form.Value} handleDeleteChip={(e) => handleDeleteChip(e)} handleAddChip={(e) => setForm({ ...form, Value: [...form?.Value, e] })} />
                                </div>
                                <div className=''>
                                    <FormControlLabel
                                        control={<Checkbox checked={form.Status} name="Status" onChange={(e) => setForm({ ...form, [e.target.name]: e.target.checked })} color="success" />}
                                        label="Field Status"
                                        labelPlacement="start"
                                    />
                                </div>
                            </form>
                        </div>
                    </Modal>
                    <Button onClick={() => setIsCreating(true)} variant="contained" sx={{ marginBottom: 1, marginLeft: 2 }} style={{ width: '100px' }} color='success' endIcon={<AddCircleOutlineIcon />}>
                        Create
                    </Button>
                    <DataTable
                        height={500}
                        width='100%'
                        rows={fieldState?.data?.length > 0 ? fieldState?.data : []}
                        columns={columnDefs}
                        pageSize={pageSize}
                        setPageSize={(data) => changePaginationSize(data)}
                        checkboxSelection={false}
                        pagination={true}
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                    // showQuickFilter={false} 
                    />
                          <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        handleClose={closeDeleteDialog}
        handleDelete={handleDelete}
        title={"Are you sure you want to delete this field?"}
      />
                </div>
        )
    )
}

export default Fields;



