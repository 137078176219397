import { FIELDS_REQUEST, FIELDS_SUCCESS, FIELDS_FAILURE } from '../actions/fieldsActions'
import { DISABLE_SNACKBAR } from '../actions/fieldsActions'

const initialState = {
    isLoading: false,
    message: '',
    data: '',
    status: 0
}

const fieldsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIELDS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FIELDS_SUCCESS:
            return {
                ...state,
                data: action.payload.fields,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case FIELDS_FAILURE:
            return {
                ...state,
                message: action.payload.message,
                isLoading: false,
                status: action.payload.status
            }
        case DISABLE_SNACKBAR:
            return {
                ...state,
                status: 0
            }
        default:
            return state
    }
}

export default fieldsReducer;
