import React, { useState, useEffect } from "react";
import {sendNotification, disableSnackbar} from '../store/actions/sendNotificationAction';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import TopicIcon from '@mui/icons-material/Topic';
import InputAdornment from '@mui/material/InputAdornment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SendIcon from '@mui/icons-material/Send';
import SnackBar from './reusables/Snackbar.js';

const Notification = () => {
    const dispatch = useDispatch()
    const sendNotificationState = useSelector((store) => store.notification)
    const [openSnack, setOpenSnack]= useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")
    
    const { register, handleSubmit, setError, formState: { errors } } = useForm(
        { 
          mode: "all",
          defaultValues: {},
          criteriaMode: "all",
          shouldFocusError: true,
        });
    
    const [form, setForm] = useState({})
    
    const formHandler = (e) => {
        e.preventDefault();



        
        dispatch(sendNotification(form))
        dispatch(disableSnackbar())
    }

    const handleSnackClose = () => {
        setOpenSnack(false)
        dispatch(disableSnackbar())
    }

    useEffect(()=>{
        if(sendNotificationState?.status == 200){
            setSeverity("success")
            setMessage(sendNotificationState?.message)
            setOpenSnack(true)
        }else if(sendNotificationState?.status == 400){
            setSeverity("error")
            setMessage(sendNotificationState?.message)
            setOpenSnack(true)
        }else if(sendNotificationState?.status == 206){
            setSeverity("warning")
            setMessage(sendNotificationState?.message)
            setOpenSnack(true)
        }
    },[sendNotificationState])

    return(
        <div className="home-content">
            <SnackBar handleClose={handleSnackClose} message={message} open={openSnack} severity={severity}>
            </SnackBar>
            <div style={{marginLeft:'10px', padding: '10px'}}>
                <h1 style={{fontSize:'23px',fontWeight: 'bold', marginLeft:'25px'}}>Send Notification Form</h1>
                <form className="ml-5 mt-4" onSubmit={formHandler}>
                            <div className='mb-3 mt-2'>
                                <TextField
                                        label="Topic"
                                        name="Topic"
                                        id="Topic"
                                        {...register("Topic", {required: true })}
                                        placeholder="Please enter Topic"
                                        InputLabelProps={{
                                            shrink: true,
                                            }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                            }}
                                            onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                        InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <TopicIcon />
                                            </InputAdornment>
                                        ),
                                        }}
                                        // value={form.Header}
                                        variant="outlined"
                                    />
                                    {errors.Topic && <span>This field is required!</span>}
                            </div>
                            <div className='mb-3'>
                                <TextField
                                        label="Message"
                                        name="Message"
                                        {...register("Message", {required: true })}
                                        placeholder="Please enter Message"
                                        color="primary"
                                        InputLabelProps={{
                                            shrink: true,
                                            }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                <ForwardToInboxIcon />
                                                </InputAdornment>
                                            ),
                                            }}
                                        sx={{
                                            width: 500,
                                            maxWidth: '100%',
                                            height: '100%'
                                            }}
                                        onChange={(e)=>setForm({...form,[e.target.name]: e.target.value})}
                                        variant="outlined"
                                    />
                                    {errors.Message && <span>This field is required!</span>}
                            </div>
                            <Button type="submit" startIcon={<SendIcon/>} variant="contained">Send Notification</Button>
                </form>
            </div>
        </div>
    )
}
export default Notification