

import axiosRequest from '../../helper/axiosRequest'


export const SAFETY_CONTENT_LIST = 'SAFETY_CONTENT_LIST'
export const SAFETY_CONTENT_ADD = 'SAFETY_CONTENT_ADD'
export const SAFETY_CONTENT_DELETE = 'SAFETY_CONTENT_DELETE'
export const SAFETY_CONTENT_LOADING = 'SAFETY_CONTENT_DELETE'

const BaseUrl = process.env.REACT_APP_BASE_URL


export const getSafetyContentList = () => {
    return function (dispatch) {

        dispatch({ type: SAFETY_CONTENT_LOADING, payload: true })

        axiosRequest.post('safety-content-list')
            .then(response => {


                dispatch({ type: SAFETY_CONTENT_LIST, payload: response.data.data })
            })
            .catch(error => {
                dispatch({ type: SAFETY_CONTENT_LOADING, payload: false })
            })
    }
}


