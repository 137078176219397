import React from "react";
import '../../css/modal.css';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
function Modal(props){
    if(!props.show){
        return null
    }
    return(
        <div className="modal" onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                        <h4 className="modal-title">{props.title}</h4>
                        <button onClick={props.onClose} type="button" className="rounded-md p-2 text-gray-400 hover:text-gray-500">
                            <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                <div className="modal-footer">
                    {/* <LoadingButton
                        type='submit'
                        form={props.formId}
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        sx={{
                            width: 250,
                            maxWidth: '100%',
                            height: '100%',
                        }}
                        >
                        {props.btnLeft}
                    </LoadingButton>
                    <Button 
                        onClick={props.onCancel}
                    >
                        {props.btnRight}
                    </Button> */}
                    <button type='submit' form={props.formId} className="button mr-3">{props.btnLeft}</button>
                    <button onClick={props.onCancel} className="button">{props.btnRight}</button>
                </div>
            </div>
        </div>
    )
}

export default Modal