import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './page.css'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const BASE_URL = process.env.REACT_APP_BASE_URL

const StaticPage = (props) => {
    let { slug } = useParams()

    const [page, setPage] = useState({});
    const [html, setHtml] = useState("");


    useEffect(() => {
        getPageData();
    }, [])

    const getPageData = async () => {

        let url = `${BASE_URL}static-page/${slug}`;
        const res = await axios.get(url);

        if (res.data) {
            let page = res?.data?.page;
            setPage(page)
            setHtml(res?.data?.page?.detail)
        }

    }


    if ([null, undefined, ""].includes(page) || Object.keys(page).length === 0) {
        return (
            <div style={{
                height: '100vh',
                width: '100%',
                justifyContent: "center",
                display: "flex",
                alignItems: "center"
            }}>
                <h1> 404 | Not Found </h1>
            </div>
        )
    }

    return (
        <>
            {/* <div class="header_main" style={{ background: "#0a2558" }}>
                <div class="container">
                    <div class="row">
                        <div style={{ height: "60px", width: "100%", display: "flex", alignItems: "center" }}>
                            <h2 style={{ marginLeft: 30, color: "white", fontSize: 24, fontWeight: "bold" }}> Findme </h2>
                        </div>
                    </div>
                </div>
            </div> */}

            {

                [null, undefined, ""].includes(page) || Object.keys(page).length === 0 ?
                    <div style={{
                        height: '100vh',
                        width: '100%',
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <h1> 404 | Not Found </h1>
                    </div>
                    :
                    <div className="card">
                        <h1 className="primary-heading"> {page?.title} </h1>
                        <div style={{ padding: '50px' }}>
                            <p dangerouslySetInnerHTML={{ __html: html }}>

                            </p>
                        </div>
                    </div>
            }

            <Footer />
        </>
    )
}

export default StaticPage