import axios from 'axios';
import axiosRequest from '../../helper/axiosRequest';
const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS'
const FETCH_PAGES_FAILURE = 'FETCH_PAGES_FAILURE'
const FETCH_PAGES_REQUEST = 'FETCH_PAGES_REQUEST'
const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST'
const CREATE_PAGE_FAILURE = 'CREATE_PAGE_FAILURE'
const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST'
const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE'
const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST'
const UPDATE_PAGE_FAILURE = 'UPDATE_PAGE_FAILURE'

const BaseUrl = process.env.REACT_APP_BASE_URL

const fetchPages = () => {
    return function (dispatch) {
        dispatch(fetchPagesRequest())
        axiosRequest.post('getIntro')
            .then(response => {
                console.log('response', response.data.pages)
                dispatch(fetchPagesSuccess(response.data))
            })
            .catch(error => {
                dispatch(fetchPagesFailure(error.message))
            })
    }
}
const fetchPagesRequest = () => {
    return {
        type: FETCH_PAGES_REQUEST,
    }
}

const fetchPagesSuccess = (pages) => {
    return {
        type: FETCH_PAGES_SUCCESS,
        payload: pages,
        message: pages.message,
    }
}
const fetchPagesFailure = (error) => {
    return {
        type: FETCH_PAGES_FAILURE,
        payload: error,
    }
}

export { FETCH_PAGES_SUCCESS, FETCH_PAGES_FAILURE, FETCH_PAGES_REQUEST, fetchPagesRequest, fetchPagesSuccess, fetchPagesFailure, fetchPages }

const createPage = (pageData) => {
    return async function (dispatch) {
        dispatch(createPageRequest())
        axiosRequest.post('createIntro', { pageData })
            .then(response => {
                dispatch(fetchPagesSuccess(response.data))
            })
            .catch(error => {
                dispatch(createPageFailure(error.message))
            })

    }
}
const createPageRequest = () => {
    return {
        type: CREATE_PAGE_REQUEST,
    }
}

const createPageFailure = (error) => {
    return {
        type: CREATE_PAGE_FAILURE,
        payload: error,
        message: error.message
    }
}

export { CREATE_PAGE_REQUEST, CREATE_PAGE_FAILURE, createPageRequest, createPageFailure, createPage }


const deletePage = (pageId) => {
    return async function (dispatch) {
        dispatch(deletePageRequest)
        axiosRequest.post('deleteIntro', { pageId: pageId })
            .then(response => {
                dispatch(fetchPagesSuccess(response.data))
            })
            .catch(error => {
                dispatch(deletePageFailure(error))
            })
    }
}

const deletePageRequest = () => {
    return {
        type: DELETE_PAGE_REQUEST,
    }
}

const deletePageFailure = (error) => {
    return {
        type: DELETE_PAGE_FAILURE,
        payload: error,
        message: error.message,
    }
}

export { deletePage, deletePageFailure, deletePageRequest, DELETE_PAGE_FAILURE, DELETE_PAGE_REQUEST }

const updatePageAction = (pageData) => {
    return async function (dispatch) {
        dispatch(updatePageRequest())
        axiosRequest.post('updateIntro', { pageData: pageData })
            .then(response => {
                dispatch(fetchPagesSuccess(response.data))
            })
            .catch(error => {
                dispatch(updatePageFailure(error))
            })
    }
}

const updatePageRequest = () => {
    return {
        type: 'UPDATE_PAGE_REQUEST'
    }
}
const updatePageFailure = (error) => {
    return {
        type: 'UPDATE_PAGE_FAILURE',
        payload: error,
        message: error.message
    }
}

export { updatePageAction, updatePageFailure, updatePageRequest, UPDATE_PAGE_FAILURE, UPDATE_PAGE_REQUEST }