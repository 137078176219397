import React, { useState, useEffect } from 'react'
import './page.css'
import Footer from '../components/Footer';
import axios from 'axios';
import { useDispatch } from "react-redux";

import './contact-page.css'


const BASE_URL = process.env.REACT_APP_BASE_URL

const SupportPage = (props) => {

    const dispatch = useDispatch()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");



    const handleSubmit = async (e) => {
        e.preventDefault();


        let data = {
            name,
            email,
            description
        }

        let result = await axios.post(`${BASE_URL}contact-us`, data);
        alert("Your query has been sent successfully");
        console.log(result.data)

        setName("")
        setEmail("")
        setDescription("")
    }

    return (
        <>

            <div className='main-div'>
                <section id="contact">
                    <h1 className="section-header" style={{ color: "black" }}>Contact Us</h1>
                    <div className="contact-wrapper">
                        <form id="contact-form" className="form-horizontal" role="form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className="col-sm-12">
                                    <input type="text" style={{ color: "black" }} className="form-control"  placeholder="NAME" 
                                    onChange={(e) => setName(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-12">
                                    <input type="email" className="form-control" id="email" placeholder="EMAIL" name="email" 
                                    onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                            </div>
                            <textarea className="form-control" rows="10" placeholder="MESSAGE" name="message"
                            onChange={(e) => setDescription(e.target.value)} required></textarea>
                            <button className="btn btn-primary send-button" id="submit" type="submit"  style={{ backgroundColor: "#0d6efd" }}>
                                <div className="alt-send-button">
                                    <i className="fa fa-paper-plane"></i><span className="send-text">SEND</span>
                                </div>
                            </button>
                        </form>

                        <div className="direct-contact-container">
                            {/* <ul className="contact-list">
                                <li className="list-item"><i className="fa fa-map-marker fa-2x"><span className="contact-text place">City, State</span></i></li>
                                <li className="list-item"><i className="fa fa-phone fa-2x"><span className="contact-text phone"><a href="tel:1-212-555-5555" title="Give me a call">(212) 555-2368</a></span></i></li>
                                <li className="list-item"><i className="fa fa-envelope fa-2x"><span className="contact-text gmail"><a href="mailto:#" title="Send me an email">hitmeup@gmail.com</a></span></i></li>
                            </ul> */}
                        </div>
                    </div>
                </section>
            </div>




            <Footer />

        </>
    )
}

export default SupportPage